import {observer} from "mobx-react";
import {growlStore} from "../../store";

import "./Growl.scss"

const Growl = observer(function Growl() {
    return <div className={"growl"}>
        {growlStore.messages.map((m, i) => {
            const action = m.action
            return <div key={i} className={`notification is-${m.type}`}>
                <button className="delete" onClick={e => growlStore.remove(m)}></button>
                <div className={"text"}>{m.message}</div>
                {action ? <div className={"button-wrapper"}>
                    <button onClick={async () => {
                        growlStore.remove(m)
                        if (m.action) await m.action()
                    }} className={"button is-primary is-small"}>{m.actionLabel || ''}</button>
                </div> : <></>}
                {m.autoHide ?
                    <progress className={`progress is-${m.type}`} value={m.progressIndicator} max="100">
                    </progress> : <></>}
            </div>
        })}
    </div>
})

export {
    Growl
}
