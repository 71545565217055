import {FieldInput} from "../../../ui";
import {registrationStore, registrationValidationStore} from "../../../../store";
import {observer} from "mobx-react";

interface RegistrationCertificationOfRegistrationSerialProps {
    required: boolean
}


const RegistrationCertificationOfRegistrationSerialField = observer(function RegistrationCertificationOfRegistrationSerialField(
    {
        required
    }: RegistrationCertificationOfRegistrationSerialProps) {
    const corSerialHelp = registrationValidationStore.getFor('certificateOfRegistrationSerial')
    return <FieldInput
        maxLength={20}
        disabled={registrationStore.isReadonly}
        label={"ZBI Nummer"}
        required={required}
        onInput={v => v.currentTarget.value = v.currentTarget.value.toUpperCase()}
        value={registrationStore.certificateOfRegistrationSerial}
        onChange={v => registrationStore.certificateOfRegistrationSerial = v}
        help={corSerialHelp}
        onBlur={() => registrationStore.validate()}
        invalid={Boolean(corSerialHelp)}
    />
})

export {
    RegistrationCertificationOfRegistrationSerialField
}
