import {FieldSelect} from "../../../ui";
import {registrationStore, registrationValidationStore} from "../../../../store";
import {RegistrationDtoVehicleTypeEnum, vehicleType} from "../../../../api";
import {observer} from "mobx-react";

const vehicleTypeOptions = Object.keys(RegistrationDtoVehicleTypeEnum).map(e => ({
    value: e as RegistrationDtoVehicleTypeEnum,
    label: vehicleType(e as RegistrationDtoVehicleTypeEnum)
}))


const RegistrationVehicleTypeField = observer(function RegistrationVehicleTypeField() {
    const vehicleTypeHelp = registrationValidationStore.getFor("vehicleType")
    return <FieldSelect
        options={vehicleTypeOptions}
        label={"Typ"}
        required={true}
        disabled={registrationStore.isReadonly}
        value={registrationStore.vehicleType}
        onChange={v => registrationStore.vehicleType = v as RegistrationDtoVehicleTypeEnum}
        help={vehicleTypeHelp}
        onBlur={() => registrationStore.validate()}
        invalid={Boolean(vehicleTypeHelp)}
    />
})

export {
    RegistrationVehicleTypeField
}
