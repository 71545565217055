import {makeAutoObservable} from "mobx";
import {
    api,
    catchApi,
    GetRegistrationListParamsSortTypeEnum,
    GetRegistrationListParamsStatusEnum,
    GetRegistrationListParamsTypeEnum,
    RegistrationListItemDto,
    RegistrationListItemDtoOrderStatusEnum,
    RegistrationListItemDtoOrderTypeEnum,
    TenantConfigDto,
} from "../api";
import {ArchiveStatus} from "./RegistrationOverviewFilterStore";

enum SortType {
    ASC = "ASC",
    DESC = "DESC",
}

enum SortColumn {

    ORDER_TYPE = 'ORDER_TYPE',
    CREATION_DATE = 'CREATION_DATE',
    ORDER_STATUS = 'ORDER_STATUS',
    HOLDER = 'HOLDER',
    VEHICLE_IDENTIFICATION_NUMBER = 'VEHICLE_IDENTIFICATION_NUMBER',
    ORDER_NUMBER = 'ORDER_NUMBER',
}

class RegistrationOverviewStore {
    get tenantConfig(): TenantConfigDto | undefined {
        return this._tenantConfig;
    }

    set tenantConfig(value: TenantConfigDto | undefined) {
        this._tenantConfig = value;
    }

    get sortType(): GetRegistrationListParamsSortTypeEnum {
        return this._sortType;
    }

    set sortType(value: GetRegistrationListParamsSortTypeEnum) {
        this._sortType = value;
    }
    get sortBy(): string {
        return this._sortBy;
    }

    set sortBy(value: string) {
        this._sortBy = value;
    }
    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: number) {
        this._totalPages = value;
    }

    get registrations(): RegistrationListItemDto[] {
        return this._registrations;
    }

    set registrations(value: RegistrationListItemDto[]) {
        this._registrations = value;
    }


    get search(): string {
        return this._search;
    }

    set search(value: string) {
        this._search = value;
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get size(): number {
        return this._size;
    }

    set size(value: number) {
        this._size = value;
    }


    get selectedServiceTypes(): RegistrationListItemDtoOrderTypeEnum[] {
        return this._selectedServiceTypes;
    }

    set selectedServiceTypes(value: RegistrationListItemDtoOrderTypeEnum[]) {
        this._selectedServiceTypes = value;
    }

    get selectedStatus(): RegistrationListItemDtoOrderStatusEnum[] {
        return this._selectedStatus;
    }

    set selectedStatus(value: RegistrationListItemDtoOrderStatusEnum[]) {
        this._selectedStatus = value;
    }

    get from(): string | undefined {
        return this._from;
    }

    set from(value: string | undefined) {
        this._from = value;
    }

    get to(): string | undefined {
        return this._to;
    }

    set to(value: string | undefined) {
        this._to = value;
    }

    get organizationId(): string | undefined {
        return this._organizationId;
    }

    set organizationId(value: string | undefined) {
        this._organizationId = value;
    }

    get archived(): ArchiveStatus {
        return this._archived;
    }

    set archived(value: ArchiveStatus) {
        this._archived = value;
    }

    formatDate(dateString: string): string {
        const toFormat = new Date(dateString);
        const yyyy = toFormat.getFullYear();
        let mm = toFormat.getMonth() + 1; // Months start at 0!
        let dd = toFormat.getDate();

        return `${dd < 10 ? `0${dd}` : dd}.${mm < 10 ? `0${mm}` : mm}.${yyyy}`
    }

    resolveClientOrderUrl(correlationId?: string) {
        const clientOrderUrlTemplate = this.tenantConfig?.clientOrderUrlTemplate;
        if (correlationId && clientOrderUrlTemplate) {
            return clientOrderUrlTemplate.replace("%c", correlationId)
        }
        return undefined
    }


    constructor() {
        makeAutoObservable(this)
    }

    private _registrations: RegistrationListItemDto[] = []
    private _totalPages: number = 0
    private _search: string = ""
    private _page: number = 1
    private _size: number = 25
    private _sortType: GetRegistrationListParamsSortTypeEnum = SortType.DESC as any as GetRegistrationListParamsSortTypeEnum
    private _sortBy: string = SortColumn.ORDER_NUMBER
    //filter
    private _selectedServiceTypes: RegistrationListItemDtoOrderTypeEnum[] = []
    private _selectedStatus: RegistrationListItemDtoOrderStatusEnum[] = []
    private _from: string | undefined = undefined
    private _to: string | undefined = undefined
    private _organizationId: string | undefined = undefined
    private _archived: ArchiveStatus = ArchiveStatus.HIDE_ARCHIVED

    private _tenantConfig: TenantConfigDto | undefined = undefined

    init(searchParams: URLSearchParams) {
        this.search = searchParams.get("search") || ""
        this.page = parseInt(searchParams.get("page") || "") || 1
        this.size = parseInt(searchParams.get("size") || "") || 25
        this.sortType = searchParams.get("sortType") as GetRegistrationListParamsSortTypeEnum || SortType.DESC
        this.sortBy = searchParams.get("sortBy") || SortColumn.ORDER_NUMBER
        this.selectedServiceTypes = searchParams.getAll("types") as RegistrationListItemDtoOrderTypeEnum[] || []
        this.selectedStatus = searchParams.getAll("status") as RegistrationListItemDtoOrderStatusEnum[] || []
        this.from = searchParams.get("from") || undefined
        this.to = searchParams.get("to") || undefined
        this.organizationId = searchParams.get("organisationId") || undefined
        const archivedInSearch = searchParams.get("archived");
        this.archived = archivedInSearch !== null ? archivedInSearch as ArchiveStatus : ArchiveStatus.HIDE_ARCHIVED
    }

    async load() {
        const {page, size, search, sortBy, sortType} = this
        const {from, organizationId, selectedStatus, to, selectedServiceTypes} = this
        const fromIsoDateTime = from !== undefined ? new Date(from).toISOString() : undefined
        const toIsoDateTime = to !== undefined ? new Date(to).toISOString() : undefined
        const archived: boolean | undefined = this.mapArchiveStatusToApi(this.archived)

        await catchApi(async () => {
            if (!this.tenantConfig) {
                const getTenantConfigResponse = await api.v1.getTenantConfig();
                this.tenantConfig = getTenantConfigResponse.data;
            }
            const registrationListResponse = await api.v1.getRegistrationList({
                page: page - 1,
                size,
                search,
                sortBy,
                sortType,
                status: selectedStatus as unknown as GetRegistrationListParamsStatusEnum[],
                type: selectedServiceTypes as unknown as GetRegistrationListParamsTypeEnum[],
                archived,
                organizationId,
                from: fromIsoDateTime,
                to: toIsoDateTime,

            });
            this.totalPages = registrationListResponse.data.total
            this.registrations = registrationListResponse.data.items;
        })
    }

    private mapArchiveStatusToApi(status: ArchiveStatus): boolean | undefined {
        switch (status) {
            case ArchiveStatus.HIDE_ARCHIVED:
                return false;
            case ArchiveStatus.SHOW_ARCHIVED:
                return undefined;
            case ArchiveStatus.SHOW_ARCHIVED_ONLY:
                return true;
        }
    }

    async updateOrLoad(orderNumber: number) {
        const hasElement = this.registrations.findIndex(r => r.orderNumber === orderNumber) !== -1
        if (hasElement) {
            await catchApi(async () => {
                const singleRegistrationSearchResponse = await api.v1.getRegistrationList({
                        search: `${orderNumber}`
                    }
                )
                const registrations = singleRegistrationSearchResponse.data.items
                const indexOfEntry = this.registrations.findIndex(r => r.orderNumber === orderNumber)
                if (registrations.length && indexOfEntry !== -1) {
                    this.registrations[indexOfEntry] = registrations[0]
                }
            })
        } else {
            await this.load()
        }
    }
}

const registrationOverviewStore = new RegistrationOverviewStore()


export {
    registrationOverviewStore,
    SortColumn,
    SortType,
}
