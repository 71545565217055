function debounce(fun: (a: any) => any, time: number) {
    const store: { handler: NodeJS.Timeout | undefined } = {
        handler: undefined
    }

    return (a: any) => {
        clearTimeout(store.handler)
        store.handler = setTimeout(() => {
            fun(a)
        }, time)
    }
}

function equals(o1: any, o2: any): boolean {
    const replacer = (_: any, value: any) =>
        value instanceof Object && !(value instanceof Array)
            ? Object.keys(value)
                .sort()
                .reduce((sorted: any, reducerKey: string) => {
                    sorted[reducerKey] = value[reducerKey];
                    return sorted;
                }, {})
            : value;

    const s1 = JSON.stringify(o1, replacer);
    const s2 = JSON.stringify(o2, replacer);
    return s1 === s2;
}

const idStore = { id: 0 }
function nextId() {
    idStore.id += 1
    return idStore.id
}

const saveData = (function () {
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style.display = 'none'
    return function (blob: Blob, fileName: string) {
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.target = "_blank"
        a.click()
        window.setTimeout(() => {
            window.URL.revokeObjectURL(url)
        }, 15*60*1000)
    }
}())

export {
    nextId,
    equals,
    debounce,
    saveData
}
