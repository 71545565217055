import {observer} from "mobx-react";
import {dropdownStore, Organization, userOrganizationRoleStore} from "../../../store";

import "../organization/OrganizationListElement.scss"
import {SetUserOrganizationGroupEnum} from "../../../api";

interface OrganizationListElementProps {
    organization: Organization
}


function getRoleColor(type?: SetUserOrganizationGroupEnum) {
    return type === SetUserOrganizationGroupEnum.ADMIN ? 'is-danger' : (type === SetUserOrganizationGroupEnum.USER ? 'is-primary' : 'is-dark')
}

function getRoleDisplay(role: SetUserOrganizationGroupEnum) {
    switch (role) {
        case SetUserOrganizationGroupEnum.ADMIN: return 'Leiter'
        case SetUserOrganizationGroupEnum.MAINTAINER: return 'Verwaltung'
        case SetUserOrganizationGroupEnum.USER: return 'Bearbeiter'
    }
}

function RoleSelectItem({type, active, selectId, organizationId}: {type: SetUserOrganizationGroupEnum | undefined, selectId: string, active?: SetUserOrganizationGroupEnum, organizationId: string}) {
    return type === active ? <></> : <a href={"#update"} onClick={async (e) => {
        e.preventDefault()
        e.stopPropagation()
        dropdownStore.toggle(selectId)
        await userOrganizationRoleStore.setUserOrganizationRole(organizationId, type)
    }} className={"dropdown-item"}>
        {type ?
            (<>Rolle <span className={`tag is-small is-rounded ${getRoleColor(type)}`}>{getRoleDisplay(type)}</span> setzen</>)
            : 'Rolle entfernen'}
    </a>
}

const RoleSelect = observer(function RoleSelect({type, selectId, organizationId}: {type?: SetUserOrganizationGroupEnum, selectId: string, organizationId: string}) {

    const roleColor = getRoleColor(type)

    return <div className={`dropdown ${dropdownStore.openId === selectId?'is-active':''}`}>
        <div className="dropdown-trigger">
            <span
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    dropdownStore.toggle(selectId)
                }}
                className={type ? `tag is-small is-rounded ${roleColor}`: `material-symbols-outlined`}
                style={type ? {cursor: "pointer"} : {cursor: "pointer", fontSize: '1.5em'}}
                aria-haspopup="true"
                aria-controls="dropdown-menu">
                {type ? <span>{type}</span> : `manage_accounts`}
            </span>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
                <RoleSelectItem type={SetUserOrganizationGroupEnum.USER} selectId={selectId} organizationId={organizationId} active={type} />
                <RoleSelectItem type={SetUserOrganizationGroupEnum.ADMIN} selectId={selectId} organizationId={organizationId} active={type} />
                <RoleSelectItem type={undefined} selectId={selectId} organizationId={organizationId} active={type} />
            </div>
        </div>
    </div>
})

const UserOrganizationListElement = observer(function UserOrganizationListElement({organization}: OrganizationListElementProps) {
    const role = userOrganizationRoleStore.organizationRole(organization.id) as SetUserOrganizationGroupEnum
    return <div className={`organization-list-element ${organization.data.disabled?'disabled':''}`}>
        <div className={"name"}>
            {organization.data.name}
            {!organization.data.disabled && <RoleSelect type={role} organizationId={organization.id} selectId={`userorganizationrole-${organization.id}`} />}
        </div>

        <div className={"info"}>
            {organization.children.length > 0 ? <span className="tag is-info">{organization.children.length} Standorte</span>: <></>}
        </div>
    </div>
})

export {
    UserOrganizationListElement
}
