import {observer} from "mobx-react";
import {OrganizationForm} from "./OrganizationForm";
import {
    authStore,
    DialogStore, featureStore,
    growlStore,
    organizationStore,
    organizationUserGroupStore,
    userStore
} from "../../../store";
import {AppHeader, AppPage} from "../../app";
import {Dialog, Tab} from "../../ui";
import {useParams} from "react-router-dom";
import {OrganizationUserGroupList} from "./OrganizationUserGroupList";
import {CSSProperties, useState} from "react";
import {OrganizationUserGroupCreateForm} from "./OrganizationUserGroupCreateForm";

const OrganizationDetailPage = observer(function OrganizationDetailPage() {
    const {id, tab} = useParams();
    const [dialogStore] = useState(new DialogStore())

    function openCreateDialog() {
        organizationUserGroupStore.reset()
        dialogStore.modalStore.open()
    }

    const buttonStyle: CSSProperties = {
        marginLeft: '0.5em',
        width: '9.5em',
        textAlign: 'left'
    }

    return <AppPage>
        <AppHeader title={"Standorte"}>
            {!organizationStore.disabled && <button className={`button is-primary`}
                    onClick={() => openCreateDialog()}>Benutzer zuordnen
            </button>}
            {featureStore.isActive('ORGANIZATION_DISABLE') && authStore.isAdmin && <>
                {!organizationStore.disabled &&
                    <button className={`button is-primary ${organizationStore.loading ? 'is-loading' : ''}`}
                            onClick={async () => {
                                if(await organizationStore.disable()) {
                                    growlStore.addAutoHidingSuccessMessage('Standort deaktiviert')
                                }
                            }}>
                <span className={`material-symbols-outlined inactive`}
                      style={{cursor: "pointer"}}>toggle_on</span>
                        <span style={buttonStyle}>Standort deaktivieren</span>
                    </button>}
                {organizationStore.disabled &&
                    <button className={`button is-primary ${organizationStore.loading ? 'is-loading' : ''}`}
                            onClick={async () => {
                                if(await organizationStore.enable()) {
                                    growlStore.addAutoHidingSuccessMessage('Standort aktiviert')
                                }
                            }}>
                <span className={`material-symbols-outlined inactive`}
                      style={{cursor: "pointer"}}>toggle_off</span>
                        <span style={buttonStyle}>Standort aktivieren</span>
                    </button>}
            </>}
        </AppHeader>
        <div className="tabs">
            <ul>
                <Tab title={"Übersicht"} name={"detail"} defaultTab={true}
                     path={`/organization/branch/${id}/detail`}/>
                <Tab title={"Benutzer"} name={"user"} path={`/organization/branch/${id}/user`}/>
            </ul>
        </div>
        {(tab === 'detail' || !tab) && <div style={{padding: "1.5em", paddingTop: "0"}}><OrganizationForm/>
            <div>
                <button className={`button is-primary ${organizationStore.loading ? 'is-loading' : ''}`}
                        onClick={async () => {
                            await organizationStore.save()
                            growlStore.addMessage({
                                message: `Standort gespeichert`,
                                type: 'success',
                                autoHide: true
                            })
                        }}>Speichern
                </button>
            </div>
        </div>}
        {(tab === 'user') && <OrganizationUserGroupList/>}

        <Dialog store={dialogStore} title={<>
            <span className="material-symbols-outlined"
                  style={{color: "var(--bulma-primary)", marginRight: "0.25em"}}>group</span> Benutzer zuordnen
        </>} primaryAction={async () => {
            await organizationUserGroupStore.createUserMapping()
            await userStore.load()
            await organizationUserGroupStore.load()
            organizationUserGroupStore.reset()
            dialogStore.modalStore.close()
            growlStore.addMessage({
                message: `Zuweisung erfolgreich`,
                type: 'success',
                autoHide: true
            })
            return null
        }} primaryActionTitle={"Anlegen"}>
            <OrganizationUserGroupCreateForm/>
        </Dialog>
    </AppPage>
})

export {
    OrganizationDetailPage
}
