import {WebStorageStateStore} from "oidc-client-ts";


const clientId = process.env.REACT_APP_OIDC_CLIENT_ID
const authority = process.env.REACT_APP_OIDC_AUTHORITY

const userStateStore = new WebStorageStateStore({ store: window.localStorage })

const onSigninCallback = () => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}

const config = {
    userStore: userStateStore,
    authority: authority,
    client_id: clientId,
    onSigninCallback: onSigninCallback
}

export {
    config,
    userStateStore
}
