import {observer} from "mobx-react";
import {AppHeader, AppPage} from "../../app";
import {FieldAutoComplete, FieldInput, FieldPhone, FieldSelect, Scroller} from "../../ui";
import {CourtEnum, courtName, courtOptions, searchCourt, tenantConfigStore} from "../../../store";
import React from "react";
import {HolderDtoRegisterTypeEnum, TenantConfigDtoRegisterTypeEnum} from "../../../api";

const registerTypeOptions: {
    label: string,
    value: string
}[] = Object.keys(HolderDtoRegisterTypeEnum)
    .filter(e => e === HolderDtoRegisterTypeEnum.HRA || e === HolderDtoRegisterTypeEnum.HRB)
    .map(e => ({label: e, value: e}))

const ConfigurationOverview = observer(function ConfigurationOverview() {
    const {config} = tenantConfigStore

    const phone = config ? {
        countryCode: config?.telephoneCountryCode || '',
            number: config?.telephoneNumber || ''
    } : undefined
    return <AppPage>
        <AppHeader title={"Einstellungen"}></AppHeader>
        {config && <Scroller>
            <div style={{padding: "1.5em"}}>
                <div className={"columns"}>
                    <div className={"column is-half"}>
                        <FieldInput label={"Firmenname"} value={config?.name || ''} onChange={e => {
                            tenantConfigStore.config = {
                                ...config,
                                name: e
                            }
                        }}/>
                    </div>
                </div>
                <div className={"columns"}>
                    <div className={"column is-half"}>
                        <FieldInput label={"Street"} value={config?.street || ''} onChange={e => {
                            tenantConfigStore.config = {
                                ...config,
                                street: e
                            }
                        }}/>
                    </div>
                    <div className={"column is-one-quarter"}>
                        <FieldInput label={"Hausnummer"} value={config?.houseNumber || ''} onChange={e => {
                            tenantConfigStore.config = {
                                ...config,
                                houseNumber: e
                            }
                        }}/>
                    </div>
                    <div className={"column is-one-quarter"}>
                        <FieldInput label={"Zusatz"} value={config?.addressAddition || ''} onChange={e => {
                            tenantConfigStore.config = {
                                ...config,
                                addressAddition: e
                            }
                        }}/>
                    </div>
                </div>
                <div className={"columns"}>
                    <div className={"column is-half"}>
                        <FieldInput label={"Postleitzahl"} value={config?.postCode || ''} onChange={e => {
                            tenantConfigStore.config = {
                                ...config,
                                postCode: e
                            }
                        }}/>
                    </div>
                    <div className={"column is-half"}>
                        <FieldInput label={"Stadt"} value={config?.city || ''} onChange={e => {
                            tenantConfigStore.config = {
                                ...config,
                                city: e
                            }
                        }}/>
                    </div>
                </div>
                <div className={"columns"}>
                    <div className={"column is-full"}>
                        {phone && <FieldPhone label={"Telefonnummer"} value={{
                            countryCode: config?.telephoneCountryCode || '',
                            number: config?.telephoneNumber || ''
                        }} onChange={e => {
                            tenantConfigStore.config = {
                                ...config,
                                telephoneCountryCode: e?.countryCode || '',
                                telephoneNumber: e?.number || ''
                            }
                        }}/>}
                    </div>
                </div>
                <div className={"columns"}>
                    <div className={"column is-one-third"}>
                        <FieldAutoComplete
                            label={"Registergericht"}
                            value={config?.courtId}
                            options={courtOptions}
                            itemRenderer={e => courtName(e as CourtEnum)}
                            search={async v => {
                                return searchCourt(v)
                            }}
                            onChange={e => {
                                tenantConfigStore.config = {
                                    ...config,
                                    courtId: e!!
                                }
                            }}
                        />
                    </div>
                    <div className={"column is-one-third"}>
                        <FieldSelect label={"Registerart"} options={registerTypeOptions} value={config?.registerType}
                                     onChange={e => {
                                         tenantConfigStore.config = {
                                             ...config,
                                             registerType: e as TenantConfigDtoRegisterTypeEnum
                                         }
                                     }}/>
                    </div>
                    <div className={"column is-one-third"}>
                        <FieldInput label={"Registernummer"} value={config?.registerNumber || ''} onChange={e => {
                            tenantConfigStore.config = {
                                ...config,
                                registerNumber: e
                            }
                        }}/>
                    </div>
                </div>
                <div className={"columns"}>
                    <div className={"column is-full"}>
                        <FieldInput label={"Beschwerdestelle Datenschutz"} value={config?.dataProtectionContact || ''}
                                    onChange={e => {
                                        tenantConfigStore.config = {
                                            ...config,
                                            dataProtectionContact: e
                                        }
                                    }}/>
                    </div>
                </div>
                <div className={"columns"}>
                    <div className={"column is-full"}>
                        <FieldInput label={"Vorlage für Deeplink in Vorsystem. %c wird ersetzt durch die Auftrags-Nr. System"} value={config?.clientOrderUrlTemplate || ''}
                                    onChange={e => {
                                        tenantConfigStore.config = {
                                            ...config,
                                            clientOrderUrlTemplate: e
                                        }
                                    }}/>
                    </div>
                </div>
                <div className={"columns"}>
                    <div className={"column is-full"}>
                        <button
                            className={`button is-primary ${tenantConfigStore.saving ? 'is-loading' : ''}`}
                            disabled={!tenantConfigStore.dirty || tenantConfigStore.saving}
                            onClick={async () => {
                                await tenantConfigStore.save()
                            }}>Speichern
                        </button>
                    </div>
                </div>
            </div>
        </Scroller>}
    </AppPage>
})

export {
    ConfigurationOverview
}
