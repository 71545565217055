import {FieldInput} from "../../../ui";
import {registrationStore, registrationValidationStore} from "../../../../store";
import {observer} from "mobx-react";

interface RegistrationCertificationOfRegistrationNumberProps {
    required: boolean
}


const RegistrationCertificationOfRegistrationNumberField = observer(function RegistrationCertificationOfRegistrationNumberField(
    {
        required
    }: RegistrationCertificationOfRegistrationNumberProps) {
    const corNumberHelp = registrationValidationStore.getFor('certificateOfRegistrationNumber')
    return <FieldInput
        id={'zb2'}
        maxLength={8}
        disabled={registrationStore.isReadonly}
        label={"ZBII Nummer"}
        required={required}
        value={registrationStore.certificateOfRegistrationNumber}
        onInput={v => v.currentTarget.value = v.currentTarget.value.toUpperCase()}
        onChange={v => registrationStore.certificateOfRegistrationNumber = v}
        help={corNumberHelp}
        onBlur={() => registrationStore.validate()}
        invalid={Boolean(corNumberHelp)}
    />
})

export {
    RegistrationCertificationOfRegistrationNumberField
}
