import {observer} from "mobx-react";
import {pageLoadingStore} from "../../store";

import "./PageLoadingMask.scss"

const PageLoadingMask = observer(function PageLoadingMask() {
    return <>
        {pageLoadingStore.loading &&
            <div className={`modal is-active page-loading-mask`}>
                <div className="modal-background"></div>
                <div className="modal-content">
                    <button className={"button is-loading"}></button>
                    <div className={"info-text"}>Seite wird geladen...</div>
                </div>
            </div>}
    </>
})

export {
    PageLoadingMask
}
