import {observer} from "mobx-react";
import {AppHeader, AppPage} from "../../app";
import {DialogStore, growlStore, UserCreateStore, userListStore} from "../../../store";
import {Dialog, Pager, SearchInputField} from "../../ui";
import {UserCreateForm} from "./UserCreateForm";
import {useState} from "react";
import {UserTable} from "./UserTable";


const UserOverviewPage = observer(function UserOverviewPage() {
    const [createStore] = useState(new UserCreateStore())
    const [dialogStore] = useState(new DialogStore())

    function openCreateDialog() {
        createStore.reset()
        dialogStore.modalStore.open()
    }

    const {totalPages} = userListStore

    return <AppPage>

        <AppHeader title={"Benutzer"}>
            <SearchInputField></SearchInputField>
            <button className={"button is-primary"} onClick={() => {
                openCreateDialog()
            }}>Benutzer anlegen
            </button>
        </AppHeader>
        <UserTable/>
        <Pager total={totalPages}></Pager>
        <Dialog store={dialogStore} title={<>
            <span className="material-symbols-outlined"
                  style={{color: "var(--bulma-primary)", marginRight: "0.25em"}}>group</span> Benutzer anlegen
        </>} primaryAction={async () => {
            const submitResult = await createStore.submit()
            if (!submitResult) {
                return false
            }
            createStore.reset()
            growlStore.addMessage({
                type: 'success',
                message: 'Benutzer wurde erstellt',
                autoHide: true,
            })
            await userListStore.load()
            return true
        }} primaryActionTitle={"Anlegen"}>
            <UserCreateForm store={createStore}/>
        </Dialog>
    </AppPage>
})

export {
    UserOverviewPage
}
