import {makeAutoObservable} from "mobx";
import {api, catchApi, UserDto} from "../api";

class UserStore {
    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }
    get user(): UserDto | undefined {
        return this._user;
    }

    set user(value: UserDto | undefined) {
        this._user = value;
    }

    get userId(): string | undefined {
        return this._userId;
    }

    set userId(value: string | undefined) {
        this._userId = value;
    }

    async setUser(userId: any) {
        if (userId) {
            this.userId = userId as string
            await this.load()
        } else {
            this.userId = undefined
        }
    }


    constructor() {
        makeAutoObservable(this)
    }

    private _user: UserDto | undefined = undefined
    private _userId: string | undefined = undefined
    private _loading: boolean = false

    async load() {
        const {userId} = this
        if (userId) {
            this.loading = true
            await catchApi(async () => {
                const response = await api.v1.getUser(userId)
                this.user = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    }

    async update({
        firstName,
        lastName,
        email
                 }: {
        firstName: string
        lastName: string
        email: string
    }) {
        const user = this.user
        const id = user?.id
        if (id && user) {
            this.loading = true
            await catchApi(async () => {
                await api.v1.updateUser(id, {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    active: this.user?.active
                })
            }).finally(() => {
                this.loading = false
            })
        }
    }

    async toggleActive(user: UserDto | undefined) {
        const id = user?.id
        const active = user?.active
        if (id) {
            this.loading = true
            await catchApi(async () => {
                await api.v1.updateActive(id, !active)
            }).finally(() => {
                this.loading = false
            })
        }
    }

    async triggerRefresh(updatedUser: UserDto) {
        if (updatedUser.id === this.userId) {
            await this.load()
        }
    }

    async sendPasswordResetMail() {
        this.loading = true
        return catchApi(async () => {
            if (this.userId) {
                await api.v1.sendPasswordResetMail(this.userId)
            }
        }).finally(() => {
            this.loading = false
        })
    }
}

const userStore = new UserStore()

export {
    userStore
}
