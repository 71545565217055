import {makeAutoObservable} from "mobx";
import {CompatClient, Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {api as apiConfig} from '../config'
import {userListStore} from "./UserListStore";
import {userOrganizationRoleStore} from "./UserOrganizationRoleStore";
import {registrationOverviewStore} from "./RegistrationOverviewStore";
import {
    RegistrationCsvExportStatusDto,
    RegistrationOfficePortalAvailabilityDto,
    RegistrationStatusDto,
    UserDto
} from "../api";
import {registrationStore} from "./RegistrationStore";
import {registrationOfficePortalAvailabilityStore} from "./RegistrationOfficePortalAvailabilityStore";
import {registrationRequestHistoryStore} from "./RegistrationRequestHistoryStore";
import {registrationExportStore} from "./RegistrationExportStore";
import {routeStore} from "./RouteStore";
import {userStore} from "./UserStore";


function getTenantId(token: String) {
    const parts = token.split(".")
    const jwtContent = JSON.parse(atob(parts[1])) as {tenantId?: string}
    return jwtContent.tenantId
}

class SocketStore {
    private stompClient: CompatClient | undefined;
    constructor() {
        makeAutoObservable(this)
    }

    public build(token: string | undefined) {
        const {baseUrl} = apiConfig
        const addition = token ? `?access_token=${token}` : ''
        const uri = `${baseUrl}/ws${addition}`;
        const {stompClient} = this;
        if (stompClient) {
            stompClient.disconnect();
        }

        const client = Stomp.over(() => {
            return new SockJS(uri);
        });
        client.debug = (msg: string) => {
            //console.log(msg) // <-- activate if socket info is required
        }
        this.stompClient = client;
        if (!token) {
            return;
        }
        client.connect({}, () => {
            const prefix = `/tenant/${getTenantId(token)}`

            client.subscribe(`${prefix}/user/create`, async (result) => {
                await userListStore.load()
            });

            client.subscribe(`${prefix}/user/update`, async (result) => {
                const updatedUser = JSON.parse(result.body) as UserDto
                await userListStore.load()
                await userStore.triggerRefresh(updatedUser)
            });

            client.subscribe(`${prefix}/user/update/organization`, async (result) => {
                await userOrganizationRoleStore.loadUserOrganizationRoles()
            });

            client.subscribe(`${prefix}/status`, async (result) => {
                const status = JSON.parse(result.body) as RegistrationStatusDto
                if (routeStore.active === '/registration') {
                    await registrationOverviewStore.updateOrLoad(status.orderNumber)
                }
                await registrationStore.triggerSocketRefresh(status)
                await registrationRequestHistoryStore.triggerRefresh(status)
            });

            client.subscribe(`${prefix}/registration/export`, async (result) => {
                const status = JSON.parse(result.body) as RegistrationCsvExportStatusDto
                registrationExportStore.processExportStatus(status)
            });

            client.subscribe(`${prefix}/registration/office/portal/availability`, async (result) => {
                const body = JSON.parse(result.body) as RegistrationOfficePortalAvailabilityDto
                registrationOfficePortalAvailabilityStore.notify(body)
            });

        });
    }

}

const socketStore = new SocketStore()

export {
    SocketStore,
    socketStore
}
