import "./UploadElement.scss"
import {AddDocumentParamsEnum} from "../../../../../api";
import React, {useState} from "react";
import {registrationStore} from "../../../../../store";


const messages = new Map<AddDocumentParamsEnum, string>()
messages.set(AddDocumentParamsEnum.FEE_RECEIPT,'')
messages.set(AddDocumentParamsEnum.REGISTRATION_RECEIPT,'')
messages.set(AddDocumentParamsEnum.PRELIMINARY_REGISTRATION_PROOF,'')
messages.set(AddDocumentParamsEnum.DEREGISTRATION_RECEIPT,'')
messages.set(AddDocumentParamsEnum.REJECTION_RECEIPT,'')
messages.set(AddDocumentParamsEnum.SEPA,'Über den Button "Sepa generieren" wird das Dokument automatisch erzeugt und mit den notwendigen Daten gefüllt.\nAlternativ können Sie das Sepa Dokument hier hochladen, falls Sie bereits ein fertiges Dokument vorliegen haben.\nWählen Sie dazu die Datei aus oder ziehen diese direkt hier rein (nur PDFs möglich).')
messages.set(AddDocumentParamsEnum.POA,'Über den Button "Vollmacht generieren" wird das Dokument automatisch erzeugt und mit den notwendigen Daten gefüllt.\nAlternativ können Sie die Vollmacht hier hochladen, falls Sie bereits ein fertiges Dokument vorliegen haben.\n Wählen Sie dazu die Datei aus oder ziehen diese direkt hier rein (nur PDFs möglich).')
messages.set(AddDocumentParamsEnum.DATA_CONSENT,'Laden Sie die Einwillgung zur Datenverarbeitung hier hoch.\nWählen Sie die Datei aus oder ziehen diese direkt hier rein (nur PDFs möglich).')

function getMessage(type: AddDocumentParamsEnum): string {
    return messages.get(type) || type
}

interface UploadElementProps {
    type: AddDocumentParamsEnum
}

function extractFile(ev: React.DragEvent<HTMLDivElement>): File | undefined {
    const acceptedType = 'application/pdf'
    ev.preventDefault();
    const dataTransferItems = ev.dataTransfer.items
    const dataTransferFiles = ev.dataTransfer.files
    if (dataTransferItems && dataTransferItems.length === 1) {
        const item = dataTransferItems[0]
        if (item.kind === "file") {
            const file = item.getAsFile()
            if (file && file.type === acceptedType) {
                return file
            }
        }
    }
    if (dataTransferFiles && dataTransferFiles.length === 1) {
        const file = dataTransferFiles.item(0)
        if (file && file.type === acceptedType) {
            return file
        }
    }
    return undefined
}



function UploadElement({
                           type
                       }: UploadElementProps) {
    const [uploading, setUploading] = useState(false)

    async function upload(file: File | undefined | null) {
        if (file) {
            setUploading(true)
            try {
                await registrationStore.upload(file, type)
            } finally {
                setUploading(false)
            }
        }
    }

    return <>
        <div className={"upload-element"}>
            <div
                className={"drop-zone"}
                onDrop={async ev => {
                    const file = extractFile(ev)
                    await upload(file)
                }}
                onDragOver={ev => {
                    ev.preventDefault();
                }}
            >
                <p style={{paddingBottom: '0.5em'}}>{getMessage(type)}</p>

                <label className={"button is-primary"}>Datei auswählen
                    <input
                        type={"file"}
                        name="file"
                        accept="application/pdf"
                        style={{ display: 'none' }}
                        onChange={async e => {
                            const files = e.target.files
                            if (files && files.length === 1) {
                                await upload(files[0])
                                e.target.value = ''
                            }
                        }}
                    />
                </label>
            </div>
            {uploading && <div className={"loading-mask"}>
                <div className={"button is-loading"}></div>
            </div>}
        </div>
    </>
}

export {
    UploadElement
}
