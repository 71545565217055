import {makeAutoObservable} from "mobx";

type GrowlMessageType = 'info' | 'success'

interface GrowlMessage {
    type?: GrowlMessageType,
    message: string
    action?: () => Promise<void>
    actionLabel?: string,
    autoHide?: boolean,
    autoHideDelay?: number,
}

interface InternalGrowlMessage extends GrowlMessage {
    type: GrowlMessageType,
    createdAt: Date,
    progressIndicator: number,
    autoHideDelay: number,
}

class GrowlStore {
    get messages(): InternalGrowlMessage[] {
        return this._messages;
    }

    constructor() {
        makeAutoObservable(this)
    }

    private _messages: InternalGrowlMessage[] = []

    addAutoHidingSuccessMessage(message: string) {
        this.addMessage({
            message: message,
            type: "success",
            autoHide: true
        })
    }

    addMessage(m: GrowlMessage) {
        this._messages = [{
            ...m,
            type: m.type || 'info',
            createdAt: new Date(),
            progressIndicator: 100,
            autoHideDelay: m.autoHideDelay || 2000,
        }, ...this.messages]
        const message = this.messages[0]

        if (message.autoHide && message.autoHideDelay && message.autoHideDelay > 0) {
            const renderInMs = 100;
            const renderInterval = setInterval(() =>{
                 growlStore.recalculateLifetime(message, renderInMs)
            }, renderInMs);
            setTimeout(() => {
                clearInterval(renderInterval);
                growlStore.remove(message)
            }, message.autoHideDelay)
        }
    }

    recalculateLifetime(m: GrowlMessage, lookahead: number) {
        const message = this.messages.find(e => e === m)
        if (message) {
            const lifetimeInMs = (new Date().getTime() - message.createdAt.getTime())
            const remainingLifetime = (message.autoHideDelay - lifetimeInMs) - lookahead
            message.progressIndicator = remainingLifetime / message.autoHideDelay * 100
        }
    }
    remove(m: GrowlMessage) {
        this._messages = this.messages.filter(e => e !== m)
    }
}

const growlStore = new GrowlStore()

export {
    growlStore
}
