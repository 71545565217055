import {observer} from "mobx-react";
import {AppHeader, AppPage} from "../../app";
import {Scroller, Tab} from "../../ui";
import {useParams} from "react-router-dom";
import {DialogStore, growlStore, organizationListStore, userEditStore, userStore} from "../../../store";
import {OrganizationList} from "../organization/OrganizationList";
import {UserOrganizationListElement} from "./UserOrganizationListElement";
import {UserDetailTab} from "./UserDetailTab";
import {useState} from "react";
import {UserEditDialog} from "./UserEditDialog";

const UserDetailPage = observer(function UserDetailPage() {
    let {userId, tab} = useParams();

    const user = userStore.user
    const iconClass = user?.active ? "active" : "inactive"
    const toggleActive = async () => {
        const oldActiveState = user!!.active
        await userStore.toggleActive(user)
        growlStore.addMessage({
            message: oldActiveState ? 'Benutzer wurde deaktiviert' : 'Benutzer wurde aktiviert',
            type: 'success',
            autoHide: true
        })
    }

    const [dialogStore] = useState(new DialogStore())

    function startEdit() {
        if (user) {
            userEditStore.setup(user)
            dialogStore.open()
        }
    }

    return <AppPage>
        <AppHeader title={`Benutzer: ${user?.username || userId}`}>
            {user &&
                <button
                    className={`button is-primary ${userStore.loading ? 'is-loading' : ''}`}
                    disabled={!user.active}
                    onClick={async () => {
                        await userStore.sendPasswordResetMail()
                        growlStore.addMessage({
                            message: 'Eine E-Mail zum Zurücksetzen des Passworts wurde an den Benutzer gesendet',
                            autoHide: true,
                            type: 'success'
                        })

                    }}>
                      <span className={`material-symbols-outlined`}>lock_reset</span>
                    <span style={{
                        marginLeft: '0.5em',
                        width: '10em',
                        textAlign: 'left'
                    }}> Passwort zurücksetzen</span>

                </button>}
            {user &&
                <button className={`button is-primary ${userStore.loading ? 'is-loading' : ''}`}
                        onClick={toggleActive}>
                <span className={`material-symbols-outlined ${iconClass}`}
                      style={{cursor: "pointer"}}>{user?.active ? 'toggle_on' : 'toggle_off'}</span>
                    <span
                        style={{
                            marginLeft: '0.5em',
                            width: '9.5em',
                            textAlign: 'left'
                        }}>Benutzer {user?.active ? 'deaktivieren' : 'aktivieren'}</span>
                </button>
            }
            {user &&
                <button className={`button is-primary ${userStore.loading ? 'is-loading' : ''}`}
                        onClick={() => {
                    startEdit()
                }}> Bearbeiten</button>
            }
        </AppHeader>

        <div className="tabs">
            <ul>
                <Tab title={"Übersicht"} name={"detail"} defaultTab={true}
                     path={`/organization/user/${userId}/detail`}/>
                <Tab title={"Standorte"} name={"organization"} path={`/organization/user/${userId}/organization`}/>
            </ul>
        </div>
        <Scroller>
            {(tab === 'detail' || !tab) && user && <UserDetailTab user={user}/>}
            {tab === 'organization' && <OrganizationList
                itemRenderer={(organization) => <UserOrganizationListElement organization={organization}/>}
                elements={organizationListStore.tree}
                expanded={true}/>}
        </Scroller>
        <UserEditDialog
            store={userEditStore}
            dialogStore={dialogStore}
        />
    </AppPage>
})

export {
    UserDetailPage
}
