import {observer} from "mobx-react";
import {ReactNode} from "react";
import {ApiErrorDialog, Growl} from "../ui";
import {PageLoadingMask} from "../ui/PageLoadingMask";

interface AppPageProps {
    children?: ReactNode
}

const AppPage = observer(function AppHeader({children}: AppPageProps) {
    return <>
        {children}
        <PageLoadingMask />
        <Growl/>
        <ApiErrorDialog />
    </>
})

export {
    AppPage
}
