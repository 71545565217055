import {observer} from "mobx-react";

import {ScrollTable} from "../../../ui";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {registrationOverviewStore, SortColumn, SortType} from "../../../../store";

import "./RegistrationOverviewList.scss"
import {orderStatus, orderType, RegistrationListItemDtoOrderStatusEnum} from "../../../../api";
import {getStatusIconFor} from "../detail/RegistrationDetailHelper";
import {CSSProperties} from "react";

function getStatusColor(orderStatus: RegistrationListItemDtoOrderStatusEnum) {
    switch (orderStatus) {
        case RegistrationListItemDtoOrderStatusEnum.CREATED: {
            return "--ct-font-color-variant-or-disabled-buttons"
        }
        case RegistrationListItemDtoOrderStatusEnum.FINISHED: {
            return "--ct-primary-blue-color"
        }
        case RegistrationListItemDtoOrderStatusEnum.FAILED: {
            return "--ct-danger-color"
        }
        case RegistrationListItemDtoOrderStatusEnum.SUBMITTED: {
            return "--ct-primary-blue-color"
        }
        case RegistrationListItemDtoOrderStatusEnum.SUBMISSION_FAILED: {
            return "--ct-warning-color"
        }
        case RegistrationListItemDtoOrderStatusEnum.SUCCESS: {
            return "--ct-success-color-bright"
        }
    }
}

const Th = observer(function Th({title, sort, column, style}: {
    sort?: (sortColumn: SortColumn) => void,
    title: string,
    style?: CSSProperties | undefined,
    column?: SortColumn
}) {
    const [searchParams] = useSearchParams()
    const sortType = searchParams.get("sortType")
    const sortColumn = searchParams.get("sortBy")
    const iconName = sortType === SortType.DESC ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
    const itsme = column === sortColumn
    if (!column || !sort) {
        return <>
            <th style={style} title={title}>{title}</th>
        </>
    }
    return <>
        <th style={style} title={title} className={'sortable'}
            onClick={() => sort(column)}
        ><div style={{display:"flex"}}>{title}{itsme && <span className="material-symbols-outlined">{iconName}</span>}</div></th>
    </>
})

const ClientOrderLink = observer(function ClientOrderLink({id, correlationId}: {
    id: string,
    correlationId?: string
}) {
    const resolvedClientUrl = registrationOverviewStore.resolveClientOrderUrl(correlationId)
    if (resolvedClientUrl) {
        return <>
            <Link to={resolvedClientUrl} target={'_blank'} title={resolvedClientUrl}>
                <span>{correlationId} <span className="material-symbols-outlined" style={{ verticalAlign: 'middle', color: 'var(--ct-font-color-variant-or-disabled-buttons)' }}>open_in_new</span></span>
            </Link>
        </>
    }

    return <>
        <Link to={`/registration/${id}`}>
            {correlationId}
        </Link>
    </>
})

const RegistrationOverviewList = observer(function RegistrationOverviewList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const currentSortType = searchParams.get("sortType") || SortType.DESC

    function sort(sortBy: SortColumn) {
        searchParams.set("sortBy", sortBy)
        searchParams.set("sortType", currentSortType === SortType.DESC ? SortType.ASC : SortType.DESC)
        setSearchParams(searchParams)
    }

    return <div className={"registration-overview-list"}>
        <ScrollTable>
            <thead>
            <tr>
                <Th title={"Auftragsart"} sort={sort} column={SortColumn.ORDER_TYPE}/>
                <Th title={"Auftragsnummer"} sort={sort} column={SortColumn.ORDER_NUMBER}/>
                <Th title={"Auftragsdatum"} sort={sort} column={SortColumn.CREATION_DATE}/>
                <Th title={"Auftrags-Nr. System"}/>
                <Th title={"Halter"} sort={sort} column={SortColumn.HOLDER}/>
                <Th title={"FIN"} sort={sort} column={SortColumn.VEHICLE_IDENTIFICATION_NUMBER}/>
                <Th title={"Kennzeichen"}/>
                <Th title={"Standort"}/>
                <Th title={"Kst."}/>
                <Th title={"Status"} style={{width: '5em'}} sort={sort} column={SortColumn.ORDER_STATUS}/>
            </tr>
            </thead>
            <tbody>
            {!registrationOverviewStore.registrations.length &&
                <tr>
                    <td className="empty-colum" colSpan={9}>
                        Es konnten leider keine Einträge angezeigt werden. Bitte passen Sie Ihre Suche und Ihren Filter an.
                    </td>
                </tr>
            }
            {registrationOverviewStore.registrations.map(registration => {
                const {
                    distinguishingSign,
                    identification,
                    identificationNumber,
                    electric,
                    seasonEnd,
                    seasonStart,
                } = registration.licensePlate || {}
                const licensePlate
                    = `${distinguishingSign || ''}-${identification || ''}${identificationNumber || ''}${electric
                    ? 'E' : ''}${seasonStart ? ` ${seasonStart}/${seasonEnd}` : ''}`

                const statusIcon = getStatusIconFor(registration.orderStatus, false, false)
                const statusText = orderStatus(registration.orderStatus)
                const statusColor = getStatusColor(registration.orderStatus)
                return <tr
                    key={registration.id}
                    onClick={() => navigate(`/registration/${registration.id}`)}
                    className={registration.archived ? "row-archived" : ""}
                    style={{cursor: "pointer"}}>
                    <td title={registration.orderType}>
                        <Link to={`/registration/${registration.id}`} className={"ellipsis"}>
                            {orderType(registration.orderType)}
                        </Link>
                    </td>
                    <td title={registration.orderNumber.toString()}>
                        <Link to={`/registration/${registration.id}`}>
                            {registration.orderNumber}
                        </Link>
                    </td>
                    <td title={registrationOverviewStore.formatDate(registration.creationDate)}>
                        <Link to={`/registration/${registration.id}`}>
                            {registrationOverviewStore.formatDate(registration.creationDate)}
                        </Link>
                    </td>
                    <td title={registration.correlationId} onClick={e => e.stopPropagation()}>
                        <ClientOrderLink id={registration.id} correlationId={registration.correlationId}/>
                    </td>
                    <td title={registration.holder}>
                        <Link to={`/registration/${registration.id}`}>
                            {registration.holder}
                        </Link>
                    </td>
                    <td title={registration.vehicleIdentificationNumber}>
                        <Link to={`/registration/${registration.id}`}>
                            {registration.vehicleIdentificationNumber}
                        </Link>
                    </td>
                    <td title={licensePlate} className={"col-license-plate"}>
                        {licensePlate}
                    </td>
                    <td title={registration.organizationName}>
                        <Link to={`/registration/${registration.id}`}>
                            {registration.organizationName}
                        </Link>
                    </td>
                    <td title={registration.costCenter}>
                        <Link to={`/registration/${registration.id}`}>
                            {registration.costCenter}
                        </Link>
                    </td>
                    <td title={statusText} style={{textAlign: 'center'}}>
                        {statusIcon && <span className="material-symbols-outlined" style={{color: `var(${statusColor})`}}>{statusIcon}</span>}
                    </td>
                </tr>
            })}
            </tbody>
        </ScrollTable>
    </div>
})

export {
    RegistrationOverviewList,

}
