import {makeAutoObservable} from "mobx";
import {api, catchApi, TenantConfigDto} from "../api";
import {equals} from "../utils";

class TenantConfigStore {
    get saving(): boolean {
        return this._saving;
    }

    set saving(value: boolean) {
        this._saving = value;
    }
    get original(): TenantConfigDto | undefined {
        return this._original;
    }

    set original(value: TenantConfigDto | undefined) {
        this._original = value;
    }
    get config(): TenantConfigDto | undefined {
        return this._config;
    }

    set config(value: TenantConfigDto | undefined) {
        this._config = value;
    }

    get dirty(): boolean {
        const o1 = JSON.parse(JSON.stringify(this.original || {}))
        const o2 = JSON.parse(JSON.stringify(this.config || {}))
        return !equals(o1, o2)
    }

    private _saving: boolean = false
    private _config: TenantConfigDto | undefined
    private _original: TenantConfigDto | undefined

    constructor() {
        makeAutoObservable(this)
    }

    async load() {
        await catchApi(async () => {
            await this.loadConfig()
        })
    }

    async save() {
        const config = this.config
        if (config && this.dirty) {
            this.saving = true
            await catchApi(async () => {
                await api.v1.setTenantConfig(config)
                await this.loadConfig()
            }).finally(() => {
                this.saving = false
            })
        }
    }

    private async loadConfig() {
        const response = await api.v1.getTenantConfig()
        const data = response.data
        this.original = data
        this.config = JSON.parse(JSON.stringify(data))
    }
}

const tenantConfigStore = new TenantConfigStore()

export {
    tenantConfigStore
}
