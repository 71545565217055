import {observer} from "mobx-react";
import {DialogStore, userStore, UserEditStore} from "../../../store";
import {Dialog, FieldInput} from "../../ui";

interface UserEditDialogProps {
    dialogStore: DialogStore
    store: UserEditStore
}

const UserEditDialog = observer(function UserEditDialog({
                                                            dialogStore,
                                                            store
                                                        }: UserEditDialogProps) {
    return <>
        <Dialog store={dialogStore} title={<>
            <span className="material-symbols-outlined"
                  style={{color: "var(--bulma-primary)", marginRight: "0.25em"}}>group</span> Benutzer bearbeiten
        </>} primaryAction={async () => {
            await userStore.update({
                email: store.email,
                lastName: store.lastName,
                firstName: store.firstName
            })
            return true
        }} primaryActionTitle={"Speichern"}>
            <FieldInput
                label={"E-Mail"}
                value={store.email}
                onChange={v => {
                    store.email = v
                }}
            />
            <FieldInput
                label={"Vorname"}
                value={store.firstName}
                onChange={v => {
                    store.firstName = v
                }}
            />
            <FieldInput
                label={"Nachname"}
                value={store.lastName}
                onChange={v => {
                    store.lastName = v
                }}
            />
        </Dialog>
    </>
})

export {
    UserEditDialog
}
