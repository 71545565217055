import {observer} from "mobx-react";
import {
    AddDocumentParamsEnum,
    documentFileName,
    documentType,
    GenerateDocumentParamsEnum,
    GetDocumentParamsEnum,
    ListAvailableDocumentsEnum,
    RemoveDocumentParamsEnum,
    SendDocumentToHolderParamsEnum
} from "../../../../../api";
import {UploadElement} from "../upload/UploadElement";
import React, {ReactNode, useState} from "react";
import {DialogStore, DocumentStatusEnum, registrationStore} from "../../../../../store";

import "./DocumentElement.scss"
import {saveData} from "../../../../../utils";
import {Dialog, FieldInput} from "../../../../ui";
import * as yup from "yup";

interface DocumentElementProps {
    type: AddDocumentParamsEnum | ListAvailableDocumentsEnum
    displayOnly?: boolean
    sendable?: boolean
    disabled?: boolean
    generateDisabled?: boolean
}

function getStatus(type: AddDocumentParamsEnum | ListAvailableDocumentsEnum): DocumentStatusEnum {
    switch (type as AddDocumentParamsEnum) {
        case AddDocumentParamsEnum.DATA_CONSENT:
            return registrationStore.dataConsentStatus as any as DocumentStatusEnum
        case AddDocumentParamsEnum.POA:
            return registrationStore.poaDocumentStatus as any as DocumentStatusEnum
        case AddDocumentParamsEnum.SEPA:
            return registrationStore.sepaDocumentStatus as any as DocumentStatusEnum

        case AddDocumentParamsEnum.DEREGISTRATION_RECEIPT:
            return DocumentStatusEnum.NOT_SET
        case AddDocumentParamsEnum.FEE_RECEIPT:
            return DocumentStatusEnum.NOT_SET
        case AddDocumentParamsEnum.PRELIMINARY_REGISTRATION_PROOF:
            return DocumentStatusEnum.NOT_SET
        case AddDocumentParamsEnum.REGISTRATION_RECEIPT:
            return DocumentStatusEnum.NOT_SET
        case AddDocumentParamsEnum.REJECTION_RECEIPT:
            return DocumentStatusEnum.NOT_SET
    }
}

const SendDocumentDialog = observer(function SendDocumentDialog({
                                                                    type,
                                                                    store,
                                                                    title
                                                                }: {
    type: AddDocumentParamsEnum | ListAvailableDocumentsEnum,
    store: DialogStore,
    title: ReactNode
}) {
    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Tragen Sie hier die E-Mailadresse des Benutzers ein')
            .required('Tragen Sie hier die E-Mailadresse des Benutzers ein'),
    });

    const [email, setEmail] = useState('')
    const [validationError, setValidationError] = useState('')

    async function validateMail() {
        try {
            await validationSchema.validate({
                email: email
            })
            setValidationError('')
            store.primaryReady = true
        } catch (e: any) {
            store.primaryReady = false
            if (e.message) {
                setValidationError(e.message)
            }
        }
    }

    return <>
        <Dialog
            titleCloseable={false}
            primaryActionTitle={"Senden"}
            primaryAction={async () => {
                await registrationStore.sendDocumentTo(type as any as SendDocumentToHolderParamsEnum, email)
                setEmail('')
                return true
            }}
            title={<>
                {title} versenden
            </>}
            store={store}
        >
            <FieldInput
                value={email}
                onChange={v => setEmail(v)}
                onInput={validateMail}
                onBlur={validateMail}
                label={"E-Mail"}
                invalid={Boolean(validationError)}
                help={validationError}
                required={false}/>
        </Dialog>
    </>
})

const DocumentElement = observer(function DocumentElement({
                                                              type,
                                                              displayOnly,
                                                              sendable,
                                                              disabled,
                                                              generateDisabled,
                                                          }: DocumentElementProps) {
    const [loading, setLoading] = useState(false)
    const [sendDocumentStore] = useState(new DialogStore())
    const status = getStatus(type)
    const name = documentType(type)
    const filename = documentFileName(type)

    async function deleteDocument() {
        setLoading(true)
        await registrationStore.deleteDocument(type as any as RemoveDocumentParamsEnum)
        setLoading(false)
    }

    async function generateDocument() {
        setLoading(true)
        await registrationStore.generateDocument(type as any as GenerateDocumentParamsEnum)
        setLoading(false)
    }

    async function downloadDocument() {
        setLoading(true)
        const blob = await registrationStore.downloadDocument(type as any as GetDocumentParamsEnum)
        if (blob) {
            saveData(blob, filename)
        }
        setLoading(false)
    }

    async function sendDocument() {
        if (registrationStore.hasEmail) {
            setLoading(true)
            try {
                await registrationStore.sendDocumentToHolder(type as any as SendDocumentToHolderParamsEnum)
            } finally {
                setLoading(false)
            }
        } else {
            sendDocumentStore.primaryReady = false
            sendDocumentStore.open()
        }
    }

    return <>
        <div className={"document-element"}>
            <div className={"document-title"}>
                <div><label className={"label"}>{name}</label></div>
                {!disabled && (!displayOnly && status === DocumentStatusEnum.NOT_SET) && <>
                    <div>
                        <button disabled={generateDisabled} className={"button is-primary is-small"}
                                onClick={() => generateDocument()}>{`${name} generieren`}</button>
                    </div>
                </>}
                {sendable && <>
                    <div>
                        <button className={"button is-primary is-small"}
                                onClick={() => sendDocument()}>Versenden
                        </button>
                    </div>
                </>}

            </div>

            {(!disabled && !displayOnly && status === DocumentStatusEnum.NOT_SET) &&
                <UploadElement type={type as AddDocumentParamsEnum}/>}
            {(displayOnly || status !== DocumentStatusEnum.NOT_SET) && <>
                <div className={"document-data"}>
                    <div className={"document-name"} title={`${name} runterladen`} onClick={() => downloadDocument()}>
                        <span className="material-symbols-outlined">download</span>
                        {filename}
                    </div>
                    {!disabled && !displayOnly &&
                        <div className={"document-delete"} title={`${name} löschen`} onClick={() => deleteDocument()}>
                            <span className="material-symbols-outlined">delete</span>
                        </div>}
                    {status === DocumentStatusEnum.SIGNED && <>
                        <div className={"document-success"} title={`${name} ist signiert`}>
                            <span className="material-symbols-outlined">verified_user</span>
                        </div>
                    </>}
                    {status === DocumentStatusEnum.UNSIGNED && <>
                        <div className={"document-warning"} title={`${name} muss signiert werden`}>
                            <span className="material-symbols-outlined">warning</span>
                        </div>
                    </>}
                    {status === DocumentStatusEnum.SUBMISSION_FAILED && <>
                        <div className={"document-error"} title={'Signaturprozess fehlgeschlagen'}>
                            <span className="material-symbols-outlined">error</span>
                        </div>
                    </>}
                    {status === DocumentStatusEnum.SUBMITTED && <>
                        <div className={"document-info"} title={'Signaturprozess gestartet'}>
                            <span className="material-symbols-outlined">info</span>
                        </div>
                    </>}
                </div>
            </>}
            {status === DocumentStatusEnum.UNSIGNED && <>
                <p className={"help is-danger"}>Dokument muss signiert sein</p>
            </>}
            {loading && <div className={"loading-mask"}>
                <div className={"button is-loading"}></div>
            </div>}
        </div>
        <SendDocumentDialog title={name} type={type} store={sendDocumentStore}/>
    </>
})

export {
    DocumentElement
}
