import {makeAutoObservable} from "mobx";
import {
    api,
    catchApi,
    DeliveryInformationDtoDeliveryDocumentTypeEnum, GetActiveProcedureEnum,
    OrganizationDto,
    RegistrationDtoDataDocumentStatusEnum,
    RegistrationDtoPoaDocumentStatusEnum,
    RegistrationDtoSepaDocumentStatusEnum,
    RegistrationDtoServiceTypeEnum
} from "../api";
import {SelectOption} from "../components/ui";
import {mapOrganizationDtoToSelectOption} from "./RegistrationUtils";

class CreateRegistrationStore {
    get procedure(): GetActiveProcedureEnum | undefined {
        return this._procedure;
    }

    set procedure(value: GetActiveProcedureEnum | undefined) {
        this._procedure = value;
    }

    get city(): string | undefined {
        return this._city;
    }

    get zip(): string | undefined {
        return this._zip;
    }

    get isDeregistration(): Boolean {
        return this.serviceType === RegistrationDtoServiceTypeEnum.AB
    }

    get serviceType(): RegistrationDtoServiceTypeEnum {
        return this._serviceType;
    }

    set serviceType(value: RegistrationDtoServiceTypeEnum) {
        this._serviceType = value;
    }

    get organization(): SelectOption<OrganizationDto> | undefined {
        const orgId = this._organizationId
        if (orgId) {
            const el = this.allOrganizations.find(e => e.id === orgId)
            return el ? mapOrganizationDtoToSelectOption(el) : undefined
        }
        return undefined;
    }

    set organization(value: SelectOption<OrganizationDto> | undefined) {
        this._organizationId = value && value.value ? value.value.id : undefined
    }

    get allOrganizations(): OrganizationDto[] {
        return this._allOrganizations;
    }

    set allOrganizations(value: OrganizationDto[]) {
        this._allOrganizations = value;
    }

    get ready(): boolean {
        const cityRequired = !this.isDeregistration
        const cityValid = this.city && this.zip
        if (this.allOrganizations.length > 0 && !this.organization) {
            return false
        }
        if (cityRequired && !cityValid && !this.procedure) {
            return false
        }
        return Boolean(this.serviceType)
    }

    private _allOrganizations: OrganizationDto[] = []
    private _serviceType: RegistrationDtoServiceTypeEnum = RegistrationDtoServiceTypeEnum.AB
    private _organizationId: string | undefined
    private _city: string | undefined
    private _zip: string | undefined
    private _procedure: GetActiveProcedureEnum | undefined;

    constructor() {
        makeAutoObservable(this)
    }

    setCitySelection(selection:{
        city: string,
        zip: string
    } | undefined) {
        if (!selection) {
            this._zip = undefined
            this._city = undefined
        } else {
            this._zip = selection.zip
            this._city = selection.city
        }
        this.triggerCityCheck()
    }

    loadOrganizations() {
        catchApi(async () => {
            const organizationResponse = await api.v1.listOrganizations()
            this.allOrganizations = (organizationResponse.data || []).filter(o => !o.disabled)
            if (this.allOrganizations.length === 1) {
                this.organization = mapOrganizationDtoToSelectOption(this.allOrganizations[0])
            }
        }).catch(() => {
            this.allOrganizations = []
        })
    }

    reset() {
        this.loadOrganizations()
        this._organizationId = undefined
        this._serviceType = RegistrationDtoServiceTypeEnum.AB
    }

    async save() {
        const {
            serviceType,
            city,
            zip
        } = this
        const organizationId = this._organizationId
        return await catchApi(async () => {
            const deliveryInformation =
                this.isDeregistration ? [] :
                    [{
                        deliveryServiceType: undefined,
                        deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL
                    }];
            const response = await api.v1.addRegistration({
                serviceType: serviceType,
                archived: false,
                deregistered: serviceType === RegistrationDtoServiceTypeEnum.WZ ? true : undefined,
                dataDocumentStatus: RegistrationDtoDataDocumentStatusEnum.NOT_SET,
                fineDustBadge: false,
                poaDocumentStatus: RegistrationDtoPoaDocumentStatusEnum.NOT_SET,
                previousLicensePlateReservation: false,
                selfRegistration: false,
                sepaDocumentStatus: RegistrationDtoSepaDocumentStatusEnum.NOT_SET,
                holder: {
                    corporate: false,
                    city: city,
                    postCode: zip
                },
                deliveryInformation: deliveryInformation,
                organizationId: organizationId
            })
            return response.data
        })
    }

    async searchOrganization(value: string): Promise<SelectOption<OrganizationDto>[]> {
        return await catchApi(async () => {
            const response = await api.v1.listOrganizations()
            const data = response.data
            const v = value.toLowerCase()
            return data.filter(o => {
                return (o.id === v || o.costCenter === v || o.name.toLowerCase().split(v).length > 1) && !o.disabled
            }).map(mapOrganizationDtoToSelectOption)
        }) || []

    }

    private async triggerCityCheck() {
        this.procedure = undefined
        const {
            zip, city
        } = this
        if (zip && city) {
            await catchApi(async () => {
                const result = await api.v1.getActiveProcedure(zip, {
                    city: city
                })
                this.procedure = result.data
            })
        }
    }
}

const createRegistrationStore = new CreateRegistrationStore()

export {
    createRegistrationStore
}
