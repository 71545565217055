import {registrationStore, RegistrationStoreProp, registrationValidationStore} from "../../../../store";
import {observer} from "mobx-react";
import {FieldLicensePlate, LicensePlateData} from "../../../ui";


interface RegistrationLicensePlateFieldProps {
    disabled?: boolean
    licensePlateField: RegistrationStoreProp,
    onChange: (value: LicensePlateData) => void
}

const RegistrationLicensePlateField = observer(function RegistrationLicensePlateField({disabled, licensePlateField, onChange}: RegistrationLicensePlateFieldProps) {
    const licensePlateHelp = [
        registrationValidationStore.getFor(`${licensePlateField}`),
        registrationValidationStore.getFor(`${licensePlateField}.distinguishingSign`),
        registrationValidationStore.getFor(`${licensePlateField}.identification`),
        registrationValidationStore.getFor(`${licensePlateField}.identificationNumber`),
        registrationValidationStore.getFor(`${licensePlateField}.seasonStart`),
        registrationValidationStore.getFor(`${licensePlateField}.seasonEnd`)]
        .filter(i => Boolean(i)).length > 0 ? "1 - 3 Buchstaben & 1 - 2 Buchstaben & 1 - 4 stellige Zahl" : undefined

    return <FieldLicensePlate
        disabled={disabled !== undefined ? disabled : registrationStore.isReadonly}
        invalid={Boolean(licensePlateHelp)}
        help={licensePlateHelp}
        value={registrationStore[licensePlateField] as LicensePlateData}
        onBlur={() => registrationStore.validate()}
        onChange={v => onChange && onChange(v)}/>
})

export {
    RegistrationLicensePlateField
}
