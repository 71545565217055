import {observer} from "mobx-react";
import {organizationStore} from "../../../store";
import {FieldAutoComplete, Scroller, SelectOption} from "../../ui";
import {OrganizationDto} from "../../../api";

function map(o: OrganizationDto): SelectOption<any> {
    return {
        label: `${o.name}`,
        value: o.id
    }
}

async function search(v: string): Promise<SelectOption<any>[]> {
    return organizationStore.tenantOrganizationParentOptions.filter(o => {
        return o.id === v || o.costCenter === v || o.name.toLowerCase().split(v.toLowerCase()).length > 1
    }).map(map)
}

const OrganizationForm = observer(function OrganizationForm() {
    const organizationOptions: SelectOption<any>[] = organizationStore.tenantOrganizationParentOptions.map(map)
    const organizationSelected = organizationOptions.find(e => e.value === organizationStore.parent) || undefined

    return <>
        <Scroller>
            <div className="field">
                <label className="label">Standortname</label>
                <div className="control">
                    <input
                        className="input"
                        type="text"
                        placeholder="Name des Standorts"
                        value={organizationStore.name}
                        onChange={e => {
                            organizationStore.name = e.currentTarget.value
                        }}
                    />
                </div>
                <p className="help">Tragen Sie hier den eindeutigen Namen des Standortes ein.</p>
            </div>
            <FieldAutoComplete
                label={"Standortzuordnung"}
                help={"Tragen Sie hier den eindeutigen Namen des Standortes ein der als übergeordneter Standort dienen soll."}
                onChange={v => {
                    organizationStore.parent = v && v.value ? v.value : ''
                }}
                options={organizationOptions}
                itemRenderer={v => v.label}
                value={organizationSelected}
                search={search}
            />
            <div className="field">
                <label className="label">Kostenstelle</label>
                <div className="control">
                    <input
                        className="input"
                        type="text"
                        placeholder="Kostenstelle"
                        value={organizationStore.costCenter}
                        onChange={e => {
                            organizationStore.costCenter = e.currentTarget.value
                        }}
                    />
                </div>
                <p className="help">Tragen Sie hier die Kostenstelle des Standortes ein.</p>
            </div>

        </Scroller>
    </>
})

export {
    OrganizationForm
}
