import {
    DeliveryAddressDto,
    DeliveryAddressDtoGenderEnum,
    DeliveryInformationDto,
    DeliveryInformationDtoDeliveryDocumentTypeEnum,
    LicensePlateDto,
    OrganizationDto,
    RegistrationDto,
    RegistrationDtoServiceTypeEnum
} from "../api";
import {SelectOption} from "../components/ui";

export function mapOrganizationDtoToSelectOption(o: OrganizationDto): SelectOption<OrganizationDto> {
    return {
        label: `${o.name}`,
        value: o
    }
}

export function getFor(data: RegistrationDto, type: DeliveryInformationDtoDeliveryDocumentTypeEnum): DeliveryInformationDto | undefined {
    if (data?.deliveryInformation?.length === 1) {
        return type === DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL ? data?.deliveryInformation[0] : undefined
    }
    return data?.deliveryInformation?.filter(e => e.deliveryDocumentType === type)[0]
}

export function toDeliveryAddressDto(val: DeliveryFormData): DeliveryAddressDto {
    const {
        city,
        corporate,
        firstName,
        name,
        houseNumber,
        street,
        zip,
        title,
        addition
    } = val

    return {
        addressAddition: addition || undefined,
        street: street || undefined,
        houseNumber: houseNumber || undefined,
        firstName: corporate ? undefined : (firstName || undefined),
        name: name || undefined,
        corporate: corporate || false,
        gender: corporate ? DeliveryAddressDtoGenderEnum.NOT_PROVIDED : ((title as DeliveryAddressDtoGenderEnum) || DeliveryAddressDtoGenderEnum.NOT_PROVIDED),
        postCode: zip || undefined,
        city: city || undefined
    }
}

export function toDeliveryFormData(deliveryAddress: DeliveryAddressDto): DeliveryFormData {
    const {
        city,
        corporate,
        firstName,
        name,
        houseNumber,
        street,
        gender,
        postCode,
        addressAddition
    } = deliveryAddress

    return {
        addition: addressAddition || '',
        name: name || '',
        city: city || '',
        title: gender || '',
        zip: postCode || '',
        corporate: corporate || false,
        firstName: firstName || '',
        houseNumber: houseNumber || '',
        street: street || ''
    }
}

export interface OpenIbanResult {
    valid: boolean
    iban: string
    bankData: {
        name: string
        zip: string
        city: string
        bic: string
        bankCode: string
    }
}

export interface BankData {
    iban?: string
    bic?: string
}

export interface BankDisplayData {
    valid: boolean,
    accountNumber: string,
    iban: string
    name: string
    bankCode: string
    bic: string
}

export interface DeliveryFormData {
    corporate: boolean,
    street: string,
    houseNumber: string,
    addition: string,
    city: string,
    zip: string,
    name: string,
    firstName: string,
    title: string,
}

export enum DocumentStatusEnum {
    NOT_SET = "NOT_SET",
    SUBMITTED = "SUBMITTED",
    SIGNED = "SIGNED",
    UNSIGNED = "UNSIGNED",
    SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export async function validateIban(iban: string, bic: string | undefined): Promise<BankDisplayData> {
    try {
        const result = await fetch(`https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=false`, {
            method: 'GET'
        })
        const r = (await result.json()) as OpenIbanResult

        if (r && r.valid) {
            return {
                valid: true,
                iban: r.iban,
                bic: r.bankData.bic || bic || '',
                name: r.bankData.name,
                bankCode: r.bankData.bankCode,
                accountNumber: r.iban.split(r.bankData.bankCode)[1]
            }
        }
    } catch (e) {}

    return {
        iban: iban,
        valid: false,
        accountNumber: '',
        bic: '',
        bankCode: '',
        name: ''
    }
}

function isLicensePlateEmpty(licensePlate: LicensePlateDto | undefined) {
    if(licensePlate === undefined) return true
    return !licensePlate.distinguishingSign
        && !licensePlate.identification
        && !licensePlate.identificationNumber
        && (licensePlate.seasonStart === undefined && licensePlate.seasonEnd === undefined)
}

export function determineRetainLicensePlateByData(data: RegistrationDto) {
    if (isLicensePlateEmpty(data.licensePlate)) return false
    if (isLicensePlateEmpty(data.previousLicensePlate)) return false
    if (data.isDeregistered) return false
    if (data.serviceType !== RegistrationDtoServiceTypeEnum.UI
        && data.serviceType !== RegistrationDtoServiceTypeEnum.UM
        && data.serviceType !== RegistrationDtoServiceTypeEnum.UO
    ) return false;
    return JSON.stringify(data.licensePlate) === JSON.stringify(data.previousLicensePlate)
}

const ranks = new Map<DeliveryInformationDtoDeliveryDocumentTypeEnum | undefined, number>()
ranks.set(undefined, 0)
ranks.set(DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_I_AND_BADGES, 1)
ranks.set(DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_II, 2)
ranks.set(DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL, 3)
export function normalizeRegistration(data: RegistrationDto) {
    if (data.deliveryInformation) {
        data.deliveryInformation = data.deliveryInformation.sort((a: DeliveryInformationDto, b: DeliveryInformationDto) => {
            const ra = ranks.get(a.deliveryDocumentType) || 0
            const rb = ranks.get(b.deliveryDocumentType) || 0
            return ra - rb
        })
    }
    return data;
}
