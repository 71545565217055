import {FieldSelect} from "../../../ui";
import {registrationStore, registrationValidationStore} from "../../../../store";
import {
    RegistrationDtoUsageTypeEnum,
    vehicleUsageType
} from "../../../../api";
import {observer} from "mobx-react";

interface OptionType {
    value: RegistrationDtoUsageTypeEnum | '',
    label: string
}

// const vehicleUsageOptions: OptionType[] = [{value: '', label: 'Ohne'}, ...Object.keys(RegistrationDtoUsageTypeEnum).map(e => ({
//     value: e as RegistrationDtoUsageTypeEnum,
//     label: vehicleUsageType(e as RegistrationDtoUsageTypeEnum)
// }))]
const vehicleUsageOptions: OptionType[] = [{value: '', label: ''}, {
    value: RegistrationDtoUsageTypeEnum.S,
    label: vehicleUsageType(RegistrationDtoUsageTypeEnum.S)
}]


const RegistrationVehicleUsageField = observer(function RegistrationVehicleUsageField() {
    const usageHelp = registrationValidationStore.getFor("usageType")
    return <FieldSelect
        options={vehicleUsageOptions}
        label={"Typ"}
        required={true}
        disabled={registrationStore.isReadonly}
        value={registrationStore.usageType}
        onChange={v => {
            registrationStore.usageType = v ? (v as RegistrationDtoUsageTypeEnum) : undefined
        }}
        help={usageHelp}
        onBlur={() => registrationStore.validate()}
        invalid={Boolean(usageHelp)}
    />
})

export {
    RegistrationVehicleUsageField
}
