import {observer} from "mobx-react";
import {Navbar} from "../ui";
import {ConfigurationOverview} from "../page/configuration";
import {authStore} from "../../store";

const TenantConfigurationApp = observer(function OrganizationConfigurationApp() {
    return <div className={"app-wrapper"}>
        <Navbar />
        {authStore.isAdmin && <ConfigurationOverview />}
    </div>
})

export {
    TenantConfigurationApp
}
