/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CertificateOfDestructionDto {
  companyNumber?: string;
  /** @format date */
  date?: string;
}

export interface CertificateOfRegistrationNumberDto {
  number: string;
  serial: string;
}

export interface DeliveryAddressDto {
  addressAddition?: string;
  city?: string;
  corporate?: boolean;
  firstName?: string;
  gender?: DeliveryAddressDtoGenderEnum;
  houseNumber?: string;
  name?: string;
  postCode?: string;
  street?: string;
}

export interface DeliveryInformationDto {
  deliveryAddress?: DeliveryAddressDto;
  deliveryDocumentType?: DeliveryInformationDtoDeliveryDocumentTypeEnum;
  deliveryServiceType?: DeliveryInformationDtoDeliveryServiceTypeEnum;
}

export interface DeviatingTaxPayerDto {
  addressAddition?: string;
  city?: string;
  houseNumber?: string;
  name?: string;
  postCode?: string;
  street?: string;
}

export interface Document {
  base64Data?: string;
  name?: string;
  reference?: string;
}

export interface ErrorDto {
  fieldName: string;
  message: string;
}

export interface HolderDto {
  addressAddition?: string;
  birthName?: string;
  birthNameSuffix?: string;
  city?: string;
  cityOfBirth?: string;
  corporate: boolean;
  courtId?: string;
  /** @format date */
  dateOfBirth?: string;
  doctorate?: string;
  economicSector?: HolderDtoEconomicSectorEnum;
  emailAddress?: string;
  firstName?: string;
  gender?: HolderDtoGenderEnum;
  houseNumber?: string;
  name?: string;
  nameSuffix?: string;
  phoneNumber?: PhoneNumberDto;
  postCode?: string;
  registerNumber?: string;
  registerType?: HolderDtoRegisterTypeEnum;
  street?: string;
}

export interface ImportBankdatenDto {
  bic?: string;
  iban?: string;
  kontoinhaber?: string;
  sepaMandat?: Document;
  zahlweiseKfzSteuern?: ImportBankdatenDtoZahlweiseKfzSteuernEnum;
}

export interface ImportBevollmaechtigterDto {
  adresszusatz?: string;
  email?: string;
  hausNr?: string;
  name?: string;
  ort?: string;
  plz?: string;
  strasse?: string;
  telefon?: string;
}

export interface ImportHalterDto {
  adresszusatz?: string;
  berufsgruppe?: ImportHalterDtoBerufsgruppeEnum;
  einwilligungDatenverarbeitung?: Document;
  emailAddress?: string;
  firmenname?: string;
  /** @format date */
  geburtsdatum?: string;
  geburtsname?: string;
  geburtsnameNamenszusatz?: string;
  geburtsort?: string;
  geschlecht?: ImportHalterDtoGeschlechtEnum;
  gewerblich?: boolean;
  hausNr?: string;
  nachname?: string;
  namenszusatz?: string;
  ort?: string;
  plz?: string;
  strasse?: string;
  titel?: string;
  vorname?: string;
}

export interface ImportKennzeichenDto {
  teil1?: string;
  teil2?: string;
  teil3?: string;
}

export interface ImportRegistrationDto {
  /**
   * Your id
   * @pattern ^[a-zA-Z0-9_-]{1,255}$
   * @example "2bca3c70-959c-4521-a19b-ac75abb9d4f2"
   */
  correlationId?: string;
  dataDocument?: Document;
  poaDocument?: Document;
  registration: RegistrationDto;
  sepaDocument?: Document;
}

export interface ImportRegistrationResponseDto {
  /**
   * The correlation id passed during import
   * @pattern ^[a-zA-Z0-9_-]{1,255}$
   * @example "2bca3c70-959c-4521-a19b-ac75abb9d4f2"
   */
  correlationId?: string;
  /** @format uuid */
  id?: string;
  status: ImportRegistrationResponseDtoStatusEnum;
  tenantId: string;
}

export interface ImportVersandInformationDto {
  unterlagenTyp?: ImportVersandInformationDtoUnterlagenTypEnum;
  versandAdresse?: ImportVersandsadresseDto;
  versandTyp?: ImportVersandInformationDtoVersandTypEnum;
}

export interface ImportVersandsadresseDto {
  adresszusatz?: string;
  geschlecht?: ImportVersandsadresseDtoGeschlechtEnum;
  gewerblich?: boolean;
  hausNr?: string;
  name?: string;
  ort?: string;
  plz?: string;
  strasse?: string;
  vorname?: string;
}

export interface ImportVollmachtDto {
  bevollmaechtigter?: ImportBevollmaechtigterDto;
  vollmacht?: Document;
}

export interface ImportVorherigesKennzeichenDto {
  siegelHinten?: string;
  siegelVorne?: string;
  teil1?: string;
  teil2?: string;
  teil3?: string;
}

export interface ImportZulassungDto {
  bankdaten?: ImportBankdatenDto;
  deregistered?: boolean;
  elektroFahrzeug: boolean;
  evbNr?: string;
  fahrgestellNr?: string;
  halter?: ImportHalterDto;
  /** @format uuid */
  id?: string;
  kennzeichen?: ImportKennzeichenDto;
  reservierungsNr?: string;
  versandInformationen?: ImportVersandInformationDto[];
  vollmacht?: ImportVollmachtDto;
  vorgangsart: ImportZulassungDtoVorgangsartEnum;
  vorherigesKennzeichen?: ImportVorherigesKennzeichenDto;
  zb1Nr?: string;
  zb1Sicherheitscode?: string;
  zb2Nr?: string;
  zb2Sicherheitscode?: string;
}

export interface LicensePlateDto {
  distinguishingSign?: string;
  electric: boolean;
  identification?: string;
  identificationNumber?: string;
  /** @format int32 */
  seasonEnd?: number;
  /** @format int32 */
  seasonStart?: number;
}

export interface OrganizationCreateDto {
  costCenter?: string;
  name: string;
  /** @format uuid */
  parent?: string;
}

export interface OrganizationDto {
  costCenter?: string;
  disabled: boolean;
  /** @format uuid */
  id: string;
  name: string;
  /** @format uuid */
  parent?: string;
  /** @format int64 */
  version: number;
}

export interface OrganizationUpdateDto {
  costCenter?: string;
  /** @format uuid */
  id: string;
  name: string;
  /** @format uuid */
  parent?: string;
  /** @format int64 */
  version: number;
}

export interface PhoneNumberDto {
  countryCode?: string;
  number?: string;
}

export interface PowerOfAttorneyDto {
  addressAddition?: string;
  city?: string;
  email?: string;
  houseNumber?: string;
  name?: string;
  postCode?: string;
  street?: string;
  telephone?: string;
}

export interface RegistrationCsvExportStatusDto {
  correlationId: string;
}

export interface RegistrationDto {
  archived: boolean;
  bic?: string;
  certificateOfDestruction?: CertificateOfDestructionDto;
  certificateOfRegistrationNumber?: string;
  certificateOfRegistrationSerial?: string;
  companyInsuranceNumber?: string;
  costCenter?: string;
  dataDocumentStatus: RegistrationDtoDataDocumentStatusEnum;
  deliveryInformation?: DeliveryInformationDto[];
  deregistered?: boolean;
  deviatingTaxPayer?: DeviatingTaxPayerDto;
  fineDustBadge: boolean;
  /** @format date */
  finishedCertificateOfRegistrationIssueDate?: string;
  finishedCertificateOfRegistrationNumber?: CertificateOfRegistrationNumberDto;
  finishedLicensePlate?: LicensePlateDto;
  finishedTotalFeeAmount?: number;
  holder?: HolderDto;
  iban?: string;
  /** @format uuid */
  id?: string;
  isDeregistered?: boolean;
  /**
   * Shows the last modification date of the registration entity. This will not change if a field on one of the composite entities (like holder, deviatingTaxPayer or deliveryInformation) is changed.
   * @format date-time
   */
  lastModificationDate?: string;
  licensePlate?: LicensePlateDto;
  /**
   * Shows the most recent modification if something has change in the registration and its composite entities (like holder, powerOfAttorney, deviatingTaxPayer or deliveryInformation).
   * @format date-time
   */
  mostRecentModificationDate?: string;
  /** @format int32 */
  orderNumber?: number;
  orderStatus?: RegistrationDtoOrderStatusEnum;
  /** @format uuid */
  organizationId?: string;
  poaDocumentStatus: RegistrationDtoPoaDocumentStatusEnum;
  powerOfAttorney?: PowerOfAttorneyDto;
  previousLicensePlate?: LicensePlateDto;
  previousLicensePlateReservation: boolean;
  previousLicensePlateSealFront?: string;
  previousLicensePlateSealRear?: string;
  reservationNumber?: string;
  securityCode?: string;
  selfRegistration: boolean;
  sepaDocumentStatus: RegistrationDtoSepaDocumentStatusEnum;
  serviceType: RegistrationDtoServiceTypeEnum;
  /** @format uuid */
  signingProcessId?: string;
  taxPaymentType?: RegistrationDtoTaxPaymentTypeEnum;
  usageType?: RegistrationDtoUsageTypeEnum;
  vehicleIdentificationNumber?: string;
  /** @default "CAR" */
  vehicleType?: RegistrationDtoVehicleTypeEnum;
  verificationCode?: string;
}

export interface RegistrationListDto {
  items: RegistrationListItemDto[];
  total: number;
}

export interface RegistrationListItemDto {
  archived: boolean;
  correlationId?: string;
  costCenter?: string;
  /** @format date-time */
  creationDate: string;
  holder: string;
  /** @format uuid */
  id: string;
  licensePlate?: LicensePlateDto;
  /** @format int32 */
  orderNumber: number;
  orderStatus: RegistrationListItemDtoOrderStatusEnum;
  orderType: RegistrationListItemDtoOrderTypeEnum;
  organizationName?: string;
  vehicleIdentificationNumber: string;
}

export interface RegistrationNotificationDto {
  /** @format date-time */
  expiration?: string;
  notificationMessage: string;
  notificationType: RegistrationNotificationDtoNotificationTypeEnum;
  /** @format uuid */
  registrationRequestId: string;
}

export interface RegistrationNotificationsDto {
  header: ResponseHeaderDto;
  payload: RegistrationNotificationsPayloadDto;
}

export interface RegistrationNotificationsPayloadDto {
  notifications: RegistrationNotificationDto[];
  /** @format uuid */
  registrationId: string;
}

export interface RegistrationOfficePortalAvailabilityDto {
  correlationId?: string;
  /** @format date-time */
  lastModificationDate?: string;
  status?: RegistrationOfficePortalAvailabilityDtoStatusEnum;
}

export interface RegistrationRequestAuditDto {
  address: string;
  /** @format date-time */
  creationDate: string;
  documentPresentXkfzRequest: boolean;
  documentPresentXkfzResponse: boolean;
  principalId: string;
  /** @format uuid */
  registrationId: string;
  /** @format uuid */
  requestId: string;
  user?: UserDto;
}

export interface RegistrationStatusDto {
  documents: RegistrationStatusDtoDocumentsEnum[];
  /** @format date */
  finishedCertificateOfRegistrationIssueDate?: string;
  finishedLicensePlate?: LicensePlateDto;
  finishedTotalFeeAmount?: number;
  /** @format uuid */
  id: string;
  /**
   * Shows the last modification date of the registration entity. This will not change if a field on one of the composite entities (like holder, deviatingTaxPayer or deliveryInformation) is changed.
   * @format date-time
   */
  lastModificationDate?: string;
  /**
   * Shows the most recent modification if something has change in the registration and its composite entities (like holder, powerOfAttorney, deviatingTaxPayer or deliveryInformation).
   * @format date-time
   */
  mostRecentModificationDate?: string;
  /** @format int32 */
  orderNumber: number;
  ready: boolean;
  status: RegistrationStatusDtoStatusEnum;
  tenantId: string;
  type: RegistrationStatusDtoTypeEnum;
}

export interface ResponseHeaderDto {
  /**
   * Identifier for Kroschke projects, e.g. CARTRUST
   * @example "CARTRUST"
   */
  tenantId: string;
}

export type StreamingResponseBody = object;

export interface SubmitRegistrationDto {
  /**
   * Seal code of the license plate
   * @pattern [0-9A-Za-z]{3}
   * @example "xyz"
   */
  frontSealCode?: string;
  /**
   * Seal code of the license plate
   * @pattern [0-9A-Za-z]{3}
   * @example "xyz"
   */
  rearSealCode?: string;
  /**
   * Rubbed of ZBII security code
   * @pattern ^B[0-9A-Za-z]{10}[0-9X]$
   * @example "B0123456789X"
   */
  securityCode?: string;
  /**
   * Rubbed of ZBI security code
   * @pattern [0-9A-Za-z]{7}
   * @example "ABCDEF1"
   */
  verificationCode?: string;
}

export interface TenantConfigDto {
  addressAddition?: string;
  city: string;
  clientOrderUrlTemplate?: string;
  courtId: string;
  dataProtectionContact: string;
  economicSector: TenantConfigDtoEconomicSectorEnum;
  houseNumber: string;
  name: string;
  postCode: string;
  registerNumber: string;
  registerType: TenantConfigDtoRegisterTypeEnum;
  street: string;
  telephoneCountryCode: string;
  telephoneNumber: string;
}

export interface TenantSelectionDto {
  tenant: string;
}

export interface UserCreateDto {
  /**
   * Email address
   * @example "test@example.com"
   */
  email: string;
  /**
   * First name
   * @minLength 2
   * @maxLength 64
   * @example "Max"
   */
  firstName: string;
  /**
   * Last name
   * @minLength 2
   * @maxLength 64
   * @example "Muster"
   */
  lastName: string;
  /**
   * Username
   * @pattern ^[0-9A-Za-z.\-]{4,32}$
   * @example "max.muster"
   */
  username: string;
}

export interface UserDto {
  active: boolean;
  email: string;
  firstName: string;
  /** @format uuid */
  id: string;
  keycloak: string;
  lastName: string;
  username: string;
}

export interface UserListDto {
  items: UserListItemDto[];
  /** @format int32 */
  totalPages: number;
}

export interface UserListItemDto {
  active: boolean;
  email: string;
  firstName: string;
  /** @format uuid */
  id: string;
  keycloak: string;
  lastName: string;
  organizations: UserOrganizationListItemDto[];
  username: string;
}

export interface UserOrganizationGroupDto {
  organizationRole: Record<string, UserOrganizationGroupDtoOrganizationRoleEnum>;
}

export interface UserOrganizationListItemDto {
  costCenter?: string;
  /** @format uuid */
  id: string;
  name: string;
}

export interface UserUpdateDto {
  /** Active status */
  active?: boolean;
  /**
   * Email address
   * @example "test@example.com"
   */
  email: string;
  /**
   * First name
   * @minLength 2
   * @maxLength 64
   * @example "Max"
   */
  firstName: string;
  /**
   * Last name
   * @minLength 2
   * @maxLength 64
   * @example "Muster"
   */
  lastName: string;
}

export interface ZipCityNames {
  cityNames: string[];
  zip: string;
}

export enum DeliveryAddressDtoGenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
  DIVERSE = "DIVERSE",
  NOT_PROVIDED = "NOT_PROVIDED",
}

export enum DeliveryInformationDtoDeliveryDocumentTypeEnum {
  ALL = "ALL",
  ZB_I_AND_BADGES = "ZB_I_AND_BADGES",
  ZB_II = "ZB_II",
}

export enum DeliveryInformationDtoDeliveryServiceTypeEnum {
  VEHICLE_OWNER = "VEHICLE_OWNER",
  AUTHORIZED_REPRESENTATIVE = "AUTHORIZED_REPRESENTATIVE",
  KFZ_REGISTRATION_SERVICE = "KFZ_REGISTRATION_SERVICE",
  PICKUP = "PICKUP",
  PICKUP_BY_VEHICLE_OWNER = "PICKUP_BY_VEHICLE_OWNER",
  PICKUP_BY_KFZ_REGISTRATION_SERVICE = "PICKUP_BY_KFZ_REGISTRATION_SERVICE",
  PICKUP_BY_AUTHORIZED_REPRESENTATIVE = "PICKUP_BY_AUTHORIZED_REPRESENTATIVE",
  PICKUP_BY_THIRD = "PICKUP_BY_THIRD",
  SENDING = "SENDING",
  SENDING_TO_VEHICLE_OWNER = "SENDING_TO_VEHICLE_OWNER",
  SENDING_TO_REGISTRATION_SERVICE = "SENDING_TO_REGISTRATION_SERVICE",
  SENDING_TO_AUTHORIZED_REPRESENTATIVE = "SENDING_TO_AUTHORIZED_REPRESENTATIVE",
  SENDING_TO_THIRD = "SENDING_TO_THIRD",
  SENDING_TO_USER_ACCOUNT = "SENDING_TO_USER_ACCOUNT",
  SENDING_TO_SABRINA = "SENDING_TO_SABRINA",
  DHL = "DHL",
  DPD = "DPD",
  GLS = "GLS",
  FEDEX = "FEDEX",
  GO = "GO",
  HERMES = "HERMES",
  TNT = "TNT",
  TRANS_O_FLEX = "TRANS_O_FLEX",
  UPS = "UPS",
  OTHER = "OTHER",
}

export enum HolderDtoEconomicSectorEnum {
  A00 = "A00",
  B00 = "B00",
  C01 = "C01",
  C02 = "C02",
  C03 = "C03",
  C04 = "C04",
  C05 = "C05",
  C99 = "C99",
  D00 = "D00",
  E00 = "E00",
  F00 = "F00",
  G01 = "G01",
  G02 = "G02",
  G03 = "G03",
  G99 = "G99",
  H01 = "H01",
  H02 = "H02",
  H03 = "H03",
  H04 = "H04",
  H05 = "H05",
  H06 = "H06",
  H07 = "H07",
  I00 = "I00",
  J01 = "J01",
  J02 = "J02",
  K00 = "K00",
  L00 = "L00",
  M01 = "M01",
  M02 = "M02",
  N01 = "N01",
  N02 = "N02",
  O01 = "O01",
  O02 = "O02",
  P00 = "P00",
  Q00 = "Q00",
  R00 = "R00",
  S00 = "S00",
  U00 = "U00",
}

export enum HolderDtoGenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
  DIVERSE = "DIVERSE",
  NOT_PROVIDED = "NOT_PROVIDED",
}

export enum HolderDtoRegisterTypeEnum {
  HRA = "HRA",
  HRB = "HRB",
  GnR = "GnR",
  PR = "PR",
  VR = "VR",
}

export enum ImportBankdatenDtoZahlweiseKfzSteuernEnum {
  JAEHRLICH = "JAEHRLICH",
  ALLE_ZWEI_JAHRE = "ALLE_ZWEI_JAHRE",
  QUARTALSWEISE = "QUARTALSWEISE",
  UNVERSTEUERT = "UNVERSTEUERT",
}

export enum ImportHalterDtoBerufsgruppeEnum {
  A00 = "A00",
  B00 = "B00",
  C01 = "C01",
  C02 = "C02",
  C03 = "C03",
  C04 = "C04",
  C05 = "C05",
  C99 = "C99",
  D00 = "D00",
  E00 = "E00",
  F00 = "F00",
  G01 = "G01",
  G02 = "G02",
  G03 = "G03",
  G99 = "G99",
  H01 = "H01",
  H02 = "H02",
  H03 = "H03",
  H04 = "H04",
  H05 = "H05",
  H06 = "H06",
  H07 = "H07",
  I00 = "I00",
  J01 = "J01",
  J02 = "J02",
  K00 = "K00",
  L00 = "L00",
  M01 = "M01",
  M02 = "M02",
  N01 = "N01",
  N02 = "N02",
  O01 = "O01",
  O02 = "O02",
  P00 = "P00",
  Q00 = "Q00",
  R00 = "R00",
  S00 = "S00",
  U00 = "U00",
}

export enum ImportHalterDtoGeschlechtEnum {
  MAENNLICH = "MAENNLICH",
  WEIBLICH = "WEIBLICH",
  DIVERS = "DIVERS",
  NICHT_ANGEGEBEN = "NICHT_ANGEGEBEN",
}

export enum ImportRegistrationResponseDtoStatusEnum {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  SUCCESS = "SUCCESS",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum ImportVersandInformationDtoUnterlagenTypEnum {
  ALL = "ALL",
  ZB_I_AND_BADGES = "ZB_I_AND_BADGES",
  ZB_II = "ZB_II",
}

export enum ImportVersandInformationDtoVersandTypEnum {
  ABHOLUNG_DURCH_ZULASSUNGSDIENST = "ABHOLUNG_DURCH_ZULASSUNGSDIENST",
  ABHOLUNG_DURCH_DRITTEN = "ABHOLUNG_DURCH_DRITTEN",
  SENDUNG_AN_HALTER = "SENDUNG_AN_HALTER",
  SENDUNG_AN_DRITTEN = "SENDUNG_AN_DRITTEN",
}

export enum ImportVersandsadresseDtoGeschlechtEnum {
  MAENNLICH = "MAENNLICH",
  WEIBLICH = "WEIBLICH",
  DIVERS = "DIVERS",
  NICHT_ANGEGEBEN = "NICHT_ANGEGEBEN",
}

export enum ImportZulassungDtoVorgangsartEnum {
  NEUZULASSUNG = "NEUZULASSUNG",
  WIEDERZULASSUNG = "WIEDERZULASSUNG",
  UMSCHREIBUNG_AUSSERHALB_MIT_HALTERWECHSEL = "UMSCHREIBUNG_AUSSERHALB_MIT_HALTERWECHSEL",
  UMSCHREIBUNG_AUSSERHALB = "UMSCHREIBUNG_AUSSERHALB",
  UMSCHREIBUNG_INNERHALB = "UMSCHREIBUNG_INNERHALB",
}

export enum RegistrationDtoDataDocumentStatusEnum {
  NOT_SET = "NOT_SET",
  SUBMITTED = "SUBMITTED",
  SIGNED = "SIGNED",
  UNSIGNED = "UNSIGNED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum RegistrationDtoOrderStatusEnum {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  SUCCESS = "SUCCESS",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum RegistrationDtoPoaDocumentStatusEnum {
  NOT_SET = "NOT_SET",
  SUBMITTED = "SUBMITTED",
  SIGNED = "SIGNED",
  UNSIGNED = "UNSIGNED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum RegistrationDtoSepaDocumentStatusEnum {
  NOT_SET = "NOT_SET",
  SUBMITTED = "SUBMITTED",
  SIGNED = "SIGNED",
  UNSIGNED = "UNSIGNED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum RegistrationDtoServiceTypeEnum {
  NZ = "NZ",
  WZ = "WZ",
  UM = "UM",
  UO = "UO",
  UI = "UI",
  AB = "AB",
  TZ = "TZ",
  HA = "HA",
}

export enum RegistrationDtoTaxPaymentTypeEnum {
  ANNUALLY = "ANNUALLY",
  BIANNUALLY = "BIANNUALLY",
  QUARTERLY = "QUARTERLY",
  UNTAXED = "UNTAXED",
}

export enum RegistrationDtoUsageTypeEnum {
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  K = "K",
  L = "L",
  M = "M",
  S = "S",
  T = "T",
  X = "X",
}

/** @default "CAR" */
export enum RegistrationDtoVehicleTypeEnum {
  CAR = "CAR",
  TRAILER = "TRAILER",
  MOTORCYCLE = "MOTORCYCLE",
}

export enum RegistrationListItemDtoOrderStatusEnum {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  SUCCESS = "SUCCESS",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum RegistrationListItemDtoOrderTypeEnum {
  NZ = "NZ",
  WZ = "WZ",
  UM = "UM",
  UO = "UO",
  UI = "UI",
  AB = "AB",
  TZ = "TZ",
  HA = "HA",
}

export enum RegistrationNotificationDtoNotificationTypeEnum {
  INFO = "INFO",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  VERIMI_LOCAL_IDENT_IOS_CODE = "VERIMI_LOCAL_IDENT_IOS_CODE",
  VERIMI_LOCAL_IDENT_WEB_URL = "VERIMI_LOCAL_IDENT_WEB_URL",
  XKFZ = "XKFZ",
}

export enum RegistrationOfficePortalAvailabilityDtoStatusEnum {
  ERROR = "ERROR",
  PORTAL_FINDER_SYSTEM_NOT_AVAILABLE = "PORTAL_FINDER_SYSTEM_NOT_AVAILABLE",
  REGISTRATION_OFFICE_FOR_ADDRESS_NOT_AVAILABLE = "REGISTRATION_OFFICE_FOR_ADDRESS_NOT_AVAILABLE",
  PORTAL_FOR_REGISTRATION_OFFICE_NOT_AVAILABLE = "PORTAL_FOR_REGISTRATION_OFFICE_NOT_AVAILABLE",
  PORTAL_FOR_REGISTRATION_OFFICE_AVAILABLE = "PORTAL_FOR_REGISTRATION_OFFICE_AVAILABLE",
  PORTAL_FOR_REGISTRATION_OFFICE_TEMPORARY_NOT_AVAILABLE = "PORTAL_FOR_REGISTRATION_OFFICE_TEMPORARY_NOT_AVAILABLE",
  STATUS_FOR_REGISTRATION_OFFICE_PORTAL_NOT_IN_DATABASE = "STATUS_FOR_REGISTRATION_OFFICE_PORTAL_NOT_IN_DATABASE",
}

export enum RegistrationStatusDtoDocumentsEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum RegistrationStatusDtoStatusEnum {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  SUCCESS = "SUCCESS",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum RegistrationStatusDtoTypeEnum {
  NZ = "NZ",
  WZ = "WZ",
  UM = "UM",
  UO = "UO",
  AB = "AB",
  UI = "UI",
  TZ = "TZ",
  HA = "HA",
}

export enum TenantConfigDtoEconomicSectorEnum {
  A00 = "A00",
  B00 = "B00",
  C01 = "C01",
  C02 = "C02",
  C03 = "C03",
  C04 = "C04",
  C05 = "C05",
  C99 = "C99",
  D00 = "D00",
  E00 = "E00",
  F00 = "F00",
  G01 = "G01",
  G02 = "G02",
  G03 = "G03",
  G99 = "G99",
  H01 = "H01",
  H02 = "H02",
  H03 = "H03",
  H04 = "H04",
  H05 = "H05",
  H06 = "H06",
  H07 = "H07",
  I00 = "I00",
  J01 = "J01",
  J02 = "J02",
  K00 = "K00",
  L00 = "L00",
  M01 = "M01",
  M02 = "M02",
  N01 = "N01",
  N02 = "N02",
  O01 = "O01",
  O02 = "O02",
  P00 = "P00",
  Q00 = "Q00",
  R00 = "R00",
  S00 = "S00",
  U00 = "U00",
}

export enum TenantConfigDtoRegisterTypeEnum {
  HRA = "HRA",
  HRB = "HRB",
  GnR = "GnR",
  PR = "PR",
  VR = "VR",
}

export enum UserOrganizationGroupDtoOrganizationRoleEnum {
  USER = "USER",
  MAINTAINER = "MAINTAINER",
  ADMIN = "ADMIN",
}

export enum GetRegistrationListParamsSortTypeEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum GetRegistrationListParamsTypeEnum {
  NZ = "NZ",
  WZ = "WZ",
  UM = "UM",
  UO = "UO",
  UI = "UI",
  AB = "AB",
  TZ = "TZ",
  HA = "HA",
}

export enum GetRegistrationListParamsStatusEnum {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  SUCCESS = "SUCCESS",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum CreateExportForRegistrationListParamsTypeEnum {
  NZ = "NZ",
  WZ = "WZ",
  UM = "UM",
  UO = "UO",
  UI = "UI",
  AB = "AB",
  TZ = "TZ",
  HA = "HA",
}

export enum CreateExportForRegistrationListParamsStatusEnum {
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  SUCCESS = "SUCCESS",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export enum GetActiveProcedureEnum {
  NONE = "NONE",
  SEMI_AUTOMATIC = "SEMI_AUTOMATIC",
  AUTOMATIC = "AUTOMATIC",
}

export enum SignParamsSigningProcessTypeEnum {
  LOCAL_IDENT = "LOCAL_IDENT",
  LOCAL_IDENT_IOS = "LOCAL_IDENT_IOS",
}

export enum GetRequestAuditDocumentParamsTypeEnum {
  XKFZ_CONNECT_JSON_REQUEST = "XKFZ_CONNECT_JSON_REQUEST",
  XKFZ_CONNECT_XML_REQUEST = "XKFZ_CONNECT_XML_REQUEST",
  XKFZ_CONNECT_XML_RESPONSE = "XKFZ_CONNECT_XML_RESPONSE",
}

export enum GetRequestAuditDocumentParamsEnum {
  XKFZ_CONNECT_JSON_REQUEST = "XKFZ_CONNECT_JSON_REQUEST",
  XKFZ_CONNECT_XML_REQUEST = "XKFZ_CONNECT_XML_REQUEST",
  XKFZ_CONNECT_XML_RESPONSE = "XKFZ_CONNECT_XML_RESPONSE",
}

export enum ListAvailableDocumentsEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum GenerateDocumentParamsRequiredDocumentTypeEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum GenerateDocumentParamsEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum SendDocumentToHolderParamsRequiredDocumentTypeEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum SendDocumentToHolderParamsEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum RemoveDocumentParamsRequiredDocumentTypeEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum RemoveDocumentParamsEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum GetDocumentParamsRequiredDocumentTypeEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum GetDocumentParamsEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum AddDocumentParamsRequiredDocumentTypeEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum AddDocumentParamsEnum {
  FEE_RECEIPT = "FEE_RECEIPT",
  REGISTRATION_RECEIPT = "REGISTRATION_RECEIPT",
  REJECTION_RECEIPT = "REJECTION_RECEIPT",
  PRELIMINARY_REGISTRATION_PROOF = "PRELIMINARY_REGISTRATION_PROOF",
  DEREGISTRATION_RECEIPT = "DEREGISTRATION_RECEIPT",
  SEPA = "SEPA",
  POA = "POA",
  DATA_CONSENT = "DATA_CONSENT",
}

export enum SetUserOrganizationGroupEnum {
  USER = "USER",
  MAINTAINER = "MAINTAINER",
  ADMIN = "ADMIN",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Motor vehicle registration service from carTRUST by Kroschke
 * @version 0.84
 * @baseUrl /
 *
 * API to manage vehicle registrations
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  v1 = {
    /**
     * No description
     *
     * @tags description
     * @name EconomicSectors
     * @summary Get descriptions of economic sector enum
     * @request GET:/v1/description/enum/economic-sector
     * @secure
     */
    economicSectors: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/v1/description/enum/economic-sector`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feature
     * @name GetFeatures
     * @summary Get features
     * @request GET:/v1/feature
     * @secure
     */
    getFeatures: (params: RequestParams = {}) =>
      this.request<Record<string, boolean>, any>({
        path: `/v1/feature`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Use /v2/import instead
     *
     * @tags registration import
     * @name ImportRegistration
     * @summary import registration
     * @request POST:/v1/import
     * @deprecated
     * @secure
     */
    importRegistration: (data: ImportZulassungDto, params: RequestParams = {}) =>
      this.request<RegistrationDto, any>({
        path: `/v1/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags location
     * @name ListCities
     * @summary Find city info by zipcode
     * @request GET:/v1/location/city
     * @secure
     */
    listCities: (
      query: {
        zip: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ZipCityNames[], any>({
        path: `/v1/location/city`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name ListOrganizations
     * @summary List organizations
     * @request GET:/v1/organization
     * @secure
     */
    listOrganizations: (params: RequestParams = {}) =>
      this.request<OrganizationDto[], any>({
        path: `/v1/organization`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name CreateOrganization
     * @summary Create organization
     * @request POST:/v1/organization
     * @secure
     */
    createOrganization: (data: OrganizationCreateDto, params: RequestParams = {}) =>
      this.request<OrganizationDto, any>({
        path: `/v1/organization`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name UpdateOrganization
     * @summary Update organization
     * @request PUT:/v1/organization
     * @secure
     */
    updateOrganization: (data: OrganizationUpdateDto, params: RequestParams = {}) =>
      this.request<OrganizationDto, any>({
        path: `/v1/organization`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name EnableOrganization
     * @summary Enable organization
     * @request DELETE:/v1/organization/{id}/disabled
     * @secure
     */
    enableOrganization: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/organization/${id}/disabled`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name DisableOrganization
     * @summary Disable organization
     * @request POST:/v1/organization/{id}/disabled
     * @secure
     */
    disableOrganization: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/organization/${id}/disabled`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name ListTenantOrganizationGroups
     * @summary List user groups for organization
     * @request GET:/v1/organization/{id}/groups
     * @secure
     */
    listTenantOrganizationGroups: (id: string, params: RequestParams = {}) =>
      this.request<UserOrganizationGroupDto, any>({
        path: `/v1/organization/${id}/groups`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name GetRegistrationList
     * @summary Find all registrations
     * @request GET:/v1/registration
     * @secure
     */
    getRegistrationList: (
      query?: {
        /** @format int32 */
        page?: number;
        /** @format int32 */
        size?: number;
        search?: string;
        sortBy?: string;
        sortType?: GetRegistrationListParamsSortTypeEnum;
        /** @uniqueItems true */
        type?: GetRegistrationListParamsTypeEnum[];
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        /** @uniqueItems true */
        status?: GetRegistrationListParamsStatusEnum[];
        archived?: boolean;
        /** @format uuid */
        organizationId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RegistrationListDto, any>({
        path: `/v1/registration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name AddRegistration
     * @summary Add registration
     * @request POST:/v1/registration
     * @secure
     */
    addRegistration: (data: RegistrationDto, params: RequestParams = {}) =>
      this.request<RegistrationDto, any>({
        path: `/v1/registration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description <p>This endpoint can be used to create an export for the selected registrations</p> <p>The correlationId can be used to fetch the export /v1/registration/export/{correlationId} after it is finished.</p>
     *
     * @tags registration
     * @name CreateExportForRegistrationList
     * @summary Creates an export of all registrations
     * @request POST:/v1/registration/export
     * @secure
     */
    createExportForRegistrationList: (
      query: {
        /**
         * @minLength 10
         * @maxLength 32
         * @pattern [a-zA-Z0-9_:]+
         */
        correlationId: string;
        search?: string;
        /** @uniqueItems true */
        type?: CreateExportForRegistrationListParamsTypeEnum[];
        /** @format date-time */
        from?: string;
        /** @format date-time */
        to?: string;
        /** @uniqueItems true */
        status?: CreateExportForRegistrationListParamsStatusEnum[];
        archived?: boolean;
        /** @format uuid */
        organizationId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v1/registration/export`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns the export
     *
     * @tags registration
     * @name GetExportForRegistrationList
     * @summary Fetches the export by CorrelationId
     * @request GET:/v1/registration/export/{correlationId}
     * @secure
     */
    getExportForRegistrationList: (correlationId: string, params: RequestParams = {}) =>
      this.request<StreamingResponseBody, StreamingResponseBody>({
        path: `/v1/registration/export/${correlationId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name GetRegistrationNotifications
     * @summary Find latest notifications for current registration
     * @request GET:/v1/registration/notifications/{registrationId}
     * @secure
     */
    getRegistrationNotifications: (registrationId: string, params: RequestParams = {}) =>
      this.request<RegistrationNotificationDto[], any>({
        path: `/v1/registration/notifications/${registrationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registrationOfficePortalAvailability
     * @name TriggerRegistrationOfficePortalAvailabilityCheck
     * @summary Triggers an asynchronous updates the portal availability for the given postcode
     * @request POST:/v1/registration/office/portal/availability/{postcode}
     * @secure
     */
    triggerRegistrationOfficePortalAvailabilityCheck: (
      postcode: string,
      query: {
        correlationId: string;
        city?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v1/registration/office/portal/availability/${postcode}`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registrationOffice
     * @name GetActiveProcedure
     * @summary Receives active procedure of registration office
     * @request GET:/v1/registration/office/procedure/{postcode}
     * @secure
     */
    getActiveProcedure: (
      postcode: string,
      query?: {
        city?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetActiveProcedureEnum, any>({
        path: `/v1/registration/office/procedure/${postcode}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name Sign
     * @summary Submit documents to the signing broker
     * @request GET:/v1/registration/sign
     * @secure
     */
    sign: (
      query: {
        /** @format uuid */
        registrationId: string;
        signingProcessType?: SignParamsSigningProcessTypeEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/registration/sign`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name ValidateCandidate
     * @summary Validate payload registration data
     * @request POST:/v1/registration/validate
     * @secure
     */
    validateCandidate: (data: RegistrationDto, params: RequestParams = {}) =>
      this.request<ErrorDto[], any>({
        path: `/v1/registration/validate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name GetRegistration
     * @summary Find a registration
     * @request GET:/v1/registration/{id}
     * @secure
     */
    getRegistration: (id: string, params: RequestParams = {}) =>
      this.request<RegistrationDto, any>({
        path: `/v1/registration/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name UnarchiveRegistration
     * @summary Unarchive registration
     * @request DELETE:/v1/registration/{id}/archive
     * @secure
     */
    unarchiveRegistration: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/registration/${id}/archive`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name ArchiveRegistration
     * @summary Archive registration
     * @request POST:/v1/registration/{id}/archive
     * @secure
     */
    archiveRegistration: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/registration/${id}/archive`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name GetRequestAuditEntries
     * @summary Get request audit entries
     * @request GET:/v1/registration/{id}/audit/request
     * @secure
     */
    getRequestAuditEntries: (id: string, params: RequestParams = {}) =>
      this.request<RegistrationRequestAuditDto[], any>({
        path: `/v1/registration/${id}/audit/request`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name GetRequestAuditDocument
     * @summary Get Request Documents
     * @request GET:/v1/registration/{id}/audit/request/{requestId}/{type}
     * @secure
     */
    getRequestAuditDocument: (
      id: string,
      requestId: string,
      type: GetRequestAuditDocumentParamsEnum,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/v1/registration/${id}/audit/request/${requestId}/${type}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name ListAvailableDocuments
     * @summary Get availableDocuments
     * @request GET:/v1/registration/{id}/document
     * @secure
     */
    listAvailableDocuments: (id: string, params: RequestParams = {}) =>
      this.request<ListAvailableDocumentsEnum[], any>({
        path: `/v1/registration/${id}/document`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name GenerateDocument
     * @summary Generate Document
     * @request POST:/v1/registration/{id}/document/generate/{requiredDocumentType}
     * @secure
     */
    generateDocument: (requiredDocumentType: GenerateDocumentParamsEnum, id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/registration/${id}/document/generate/${requiredDocumentType}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name SendDocumentToHolder
     * @summary Send Document to holder
     * @request POST:/v1/registration/{id}/document/send/{requiredDocumentType}
     * @secure
     */
    sendDocumentToHolder: (
      requiredDocumentType: SendDocumentToHolderParamsEnum,
      id: string,
      query?: {
        email?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v1/registration/${id}/document/send/${requiredDocumentType}`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name RemoveDocument
     * @summary Remove document
     * @request DELETE:/v1/registration/{id}/document/{requiredDocumentType}
     * @secure
     */
    removeDocument: (requiredDocumentType: RemoveDocumentParamsEnum, id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/registration/${id}/document/${requiredDocumentType}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name GetDocument
     * @summary Get document
     * @request GET:/v1/registration/{id}/document/{requiredDocumentType}
     * @secure
     */
    getDocument: (id: string, requiredDocumentType: GetDocumentParamsEnum, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/v1/registration/${id}/document/${requiredDocumentType}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name AddDocument
     * @summary Add document
     * @request POST:/v1/registration/{id}/document/{requiredDocumentType}
     * @secure
     */
    addDocument: (
      requiredDocumentType: AddDocumentParamsEnum,
      id: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/v1/registration/${id}/document/${requiredDocumentType}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name FinishRegistration
     * @summary Set status of registration to finished
     * @request PUT:/v1/registration/{id}/finish
     * @secure
     */
    finishRegistration: (id: string, params: RequestParams = {}) =>
      this.request<RegistrationDto, RegistrationDto>({
        path: `/v1/registration/${id}/finish`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name SubmitRegistration
     * @summary Submit registration
     * @request POST:/v1/registration/{id}/submit
     * @secure
     */
    submitRegistration: (id: string, data: SubmitRegistrationDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/v1/registration/${id}/submit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name GetValidation
     * @summary Validate current registration data
     * @request POST:/v1/registration/{id}/validate
     * @secure
     */
    getValidation: (id: string, params: RequestParams = {}) =>
      this.request<ErrorDto[], any>({
        path: `/v1/registration/${id}/validate`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name UpdateRegistration
     * @summary Update registration
     * @request PUT:/v1/registration/{registrationId}
     * @secure
     */
    updateRegistration: (registrationId: string, data: RegistrationDto, params: RequestParams = {}) =>
      this.request<RegistrationDto, RegistrationDto>({
        path: `/v1/registration/${registrationId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenant-selection
     * @name ListTenants
     * @summary list tenants
     * @request GET:/v1/tenant-selection
     * @secure
     */
    listTenants: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/v1/tenant-selection`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenant-selection
     * @name LeaveTenantGroup
     * @summary delete tenant group
     * @request DELETE:/v1/tenant-selection/group
     * @secure
     */
    leaveTenantGroup: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/tenant-selection/group`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenant-selection
     * @name SwitchTenant
     * @summary switch tenant
     * @request POST:/v1/tenant-selection/switch
     * @secure
     */
    switchTenant: (data: TenantSelectionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/tenant-selection/switch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenant
     * @name GetTenantConfig
     * @summary get tenant config
     * @request GET:/v1/tenant/config
     * @secure
     */
    getTenantConfig: (params: RequestParams = {}) =>
      this.request<TenantConfigDto, any>({
        path: `/v1/tenant/config`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenant
     * @name SetTenantConfig
     * @summary set tenant config
     * @request POST:/v1/tenant/config
     * @secure
     */
    setTenantConfig: (data: TenantConfigDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/tenant/config`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ListUser
     * @summary List user
     * @request GET:/v1/user
     * @secure
     */
    listUser: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 50
         */
        size?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserListDto, any>({
        path: `/v1/user`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name CreateUser
     * @summary Create user
     * @request POST:/v1/user
     * @secure
     */
    createUser: (data: UserCreateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUsersByOrganization
     * @summary Gets all users by an organization
     * @request GET:/v1/user/organization/{id}
     * @secure
     */
    getUsersByOrganization: (id: string, params: RequestParams = {}) =>
      this.request<UserListDto, any>({
        path: `/v1/user/organization/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUser
     * @summary Get a user
     * @request GET:/v1/user/{id}
     * @secure
     */
    getUser: (id: string, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/v1/user/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UpdateUser
     * @summary Update user
     * @request PUT:/v1/user/{id}
     * @secure
     */
    updateUser: (id: string, data: UserUpdateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/user/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UpdateActive
     * @summary Update active status
     * @request POST:/v1/user/{id}/active
     * @secure
     */
    updateActive: (id: string, data: boolean, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/user/${id}/active`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUserGroupsByOrganization
     * @summary Get user groups by organizations
     * @request GET:/v1/user/{id}/organization/groups
     * @secure
     */
    getUserGroupsByOrganization: (id: string, params: RequestParams = {}) =>
      this.request<UserOrganizationGroupDto, any>({
        path: `/v1/user/${id}/organization/groups`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name DeleteUserOrganizationGroup
     * @summary Removes an organization group for an user
     * @request DELETE:/v1/user/{id}/organization/{organizationId}/groups
     * @secure
     */
    deleteUserOrganizationGroup: (id: string, organizationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/user/${id}/organization/${organizationId}/groups`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name SetUserOrganizationGroup
     * @summary Sets an organization group for an user
     * @request POST:/v1/user/{id}/organization/{organizationId}/groups
     * @secure
     */
    setUserOrganizationGroup: (
      id: string,
      organizationId: string,
      data: SetUserOrganizationGroupEnum,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v1/user/${id}/organization/${organizationId}/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name SendPasswordResetMail
     * @summary Send the user a password reset mail
     * @request POST:/v1/user/{id}/send-password-reset-mail
     * @secure
     */
    sendPasswordResetMail: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/user/${id}/send-password-reset-mail`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  v2 = {
    /**
     * No description
     *
     * @tags registration import
     * @name Import
     * @summary import
     * @request POST:/v2/import
     * @secure
     */
    import: (data: ImportRegistrationDto, params: RequestParams = {}) =>
      this.request<RegistrationDto, any>({
        path: `/v2/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
