import {observer} from "mobx-react";
import {registrationStore, registrationValidationStore} from "../../../../../../store";
import {RegistrationLicensePlateField} from "../../../fields/RegistrationLicensePlateField";
import {Checkbox} from "../../../../../ui";

const PreviousRegistrationPanelUm = observer(function PreviousRegistrationPanelUm() {
    const deregisteredHelp = registrationValidationStore.getFor('deregistered')

    return <>
        <nav className="panel">
            <p className="panel-heading">Informationen vorheriger Zulassung</p>
            <div className="panel-block">
                <div>

                    <div className={"columns"}>
                        <div className={"column is-half"}>
                            <Checkbox
                                label={'Fahrzeug ist abgemeldet'}
                                invalid={Boolean(deregisteredHelp)}
                                help={deregisteredHelp}
                                disabled={registrationStore.isReadonly}
                                value={registrationStore.isDeregistered}
                                onChange={checked => {
                                    registrationStore.updateDeregistered(checked)
                                }}
                                onBlur={() => registrationStore.validate()}
                            />
                        </div>
                        <div className={"column is-half"}>
                            <Checkbox
                                label={'Bestehendes Kennzeichen beibehalten'}
                                value={registrationStore.isRetainLicensePlate}
                                disabled={registrationStore.isReadonly}
                                onChange={checked => {
                                    registrationStore.updateRetainLicensePlate(checked)
                                }}
                                onBlur={() => registrationStore.validate()}
                            />
                        </div>
                    </div>

                    <div className={"columns"}>
                        <div className={"column is-half"} style={{display: "flex"}}>
                            <RegistrationLicensePlateField
                                licensePlateField={'previousLicensePlate'}
                                onChange={v => registrationStore.updatePreviousLicensePlate(v)}/>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </>
})

export {
    PreviousRegistrationPanelUm
}
