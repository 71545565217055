import {observer} from "mobx-react";
import {CitySelect, FieldAutoComplete, SelectOption, ServiceTypeSelect} from "../../../ui";
import {createRegistrationStore} from "../../../../store";
import {OrganizationDto, RegistrationDtoServiceTypeEnum} from "../../../../api";

function map(o: OrganizationDto): SelectOption<any> {
    return {
        label: `${o.name}`,
        value: o
    }
}

interface CreateRegistrationFormValue {
    serviceType: RegistrationDtoServiceTypeEnum,
    organization: SelectOption<OrganizationDto> | undefined,
    city: string | undefined,
    zip: string | undefined
}

interface CreateRegistrationFormProps extends CreateRegistrationFormValue {
    onChange: (v: CreateRegistrationFormValue) => void
}

const CreateRegistrationForm = observer(function CreateRegistrationForm({
                                                                            serviceType,
                                                                            organization,
                                                                            city,
                                                                            zip,
                                                                            onChange
                                                                        }: CreateRegistrationFormProps) {
    const organizationOptions = createRegistrationStore.allOrganizations.map(map)
    return <div style={{minHeight: '7em'}}>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <ServiceTypeSelect
                    id={'create-registration-service-type'}
                    label={"Vorgangsart"}
                    value={serviceType}
                    required={true}
                    onSelect={(e) => {
                        onChange({
                            serviceType: e,
                            organization: organization,
                            city: city,
                            zip: zip,
                        })
                    }}/>
            </div>
            <div className={"column is-half"}>
                <FieldAutoComplete
                    id={'create-registration-organization'}
                    label={"Standort"}
                    onChange={v => {
                        onChange({
                            serviceType: serviceType,
                            organization: v,
                            city: city,
                            zip: zip,
                        })
                    }}
                    options={organizationOptions}
                    itemRenderer={v => v.label}
                    value={organization as SelectOption<any>}
                    search={async v => {
                        return await createRegistrationStore.searchOrganization(v)
                    }}
                />
            </div>
        </div>
        {!createRegistrationStore.isDeregistration && <div className={"columns"}>
            <div className={"column is-full"}>
                <CitySelect
                    id={'create-registration-city'}
                    onChange={v => {
                        onChange({
                            serviceType: serviceType,
                            organization: organization,
                            city: v?.city,
                            zip: v?.zip,
                        })
                    }}
                />
            </div>
        </div>
        }
    </div>
})

export {
    CreateRegistrationForm
}
