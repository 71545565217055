import {makeAutoObservable} from "mobx";
import {UserDto} from "../api";

class UserEditStore {
    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }
    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }
    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    private _email: string = ''
    private _firstName: string = ''
    private _lastName: string = ''

    constructor() {
        makeAutoObservable(this)
    }

    setup(user: UserDto) {
        this.email = user.email
        this.firstName = user.firstName
        this.lastName = user.lastName
    }
}

const userEditStore = new UserEditStore()

export {
    UserEditStore,
    userEditStore
}
