import {observer} from "mobx-react";
import "./AppHeader.scss"
import {ReactNode} from "react";

interface AppHeaderProps {
    title: string | ReactNode
    children?: ReactNode
}

const AppHeader = observer(function AppHeader({title, children}: AppHeaderProps) {
    return <>
        <div className={"app-header"}>
            <div className={"header-title"}>{title}</div>
            <div className={"spacer"}></div>
            <div className={"buttons"}>
                {children}
            </div>
        </div>
    </>
})

export {
    AppHeader
}
