import {observer} from "mobx-react";
import {Dialog, ScrollTable} from "../../../ui";
import {DialogStore, registrationRequestHistoryStore} from "../../../../store";
import {Link} from "react-router-dom";
import {GetRequestAuditDocumentParamsEnum, RegistrationRequestAuditDto} from "../../../../api";
import {CSSProperties, useState} from "react";
import {saveData} from "../../../../utils";

function getFilename(dto: RegistrationRequestAuditDto, type: GetRequestAuditDocumentParamsEnum) {
    const extension = type === GetRequestAuditDocumentParamsEnum.XKFZ_CONNECT_JSON_REQUEST ? '.json' : '.xml'
    const name = type === GetRequestAuditDocumentParamsEnum.XKFZ_CONNECT_JSON_REQUEST
        ? 'kafka-request'
        : type === GetRequestAuditDocumentParamsEnum.XKFZ_CONNECT_XML_REQUEST
            ? 'xkfz-request'
            : 'xkfz-response'
    return `${dto.registrationId}-${dto.requestId}-${name}${extension}`
}

function DownloadButton({
                            dto,
                            type,
                            style,
                            text
                        }: {
    dto: RegistrationRequestAuditDto,
    type: GetRequestAuditDocumentParamsEnum,
    style?: CSSProperties | undefined,
    text: string
}) {
    const [loading, setLoading] = useState<boolean>(false)

    async function download(dto: RegistrationRequestAuditDto, type: GetRequestAuditDocumentParamsEnum) {
        setLoading(true)
        const blob = await registrationRequestHistoryStore.download(dto, type)
        if (blob) {
            const filename = getFilename(dto, type)
            saveData(blob, filename)
        }
        setLoading(false)
    }

    return <button onClick={() => download(dto, type)}
                   className={`button is-primary is-small ${loading ? 'is-loading' : ''}`}
                   style={style}>{text}</button>
}

function AlertButton({onClick}:{onClick: () => void}) {
    return <button onClick={onClick} className={"button is-primary is-small is-warning"}>
        Log
    </button>
}


const RequestLog = observer(function RequestHistory() {
    const [logLineStore] = useState(new DialogStore())
    const [activeLogLine, setActiveLogLine] = useState<RegistrationRequestAuditDto|undefined>(undefined)
    return <>
        <div style={{display: "flex", flex: "1", marginTop: "-1.5em"}}>
            <ScrollTable>
                <thead>
                <tr>
                    <th>Request-Id</th>
                    <th>Datum</th>
                    <th>IP-Adresse</th>
                    <th>Keycloak-Id</th>
                    <th style={{width: '21em'}}></th>
                </tr>
                </thead>
                <tbody>
                {registrationRequestHistoryStore.data.map(d => {
                    return <tr key={d.requestId}>
                        <td>{d.requestId}</td>
                        <td>{d.creationDate}</td>
                        <td>{d.address}</td>
                        <td>{d.user ? <Link
                            to={`/organization/user/${d.user.id}`}>{d.principalId}</Link> : d.principalId}</td>
                        <td style={{textAlign: 'right'}}>
                            <DownloadButton dto={d} type={GetRequestAuditDocumentParamsEnum.XKFZ_CONNECT_JSON_REQUEST}
                                            style={{marginRight: '0.5em'}} text={"JSON"}/>
                            {d.documentPresentXkfzRequest &&
                                <DownloadButton dto={d} type={GetRequestAuditDocumentParamsEnum.XKFZ_CONNECT_XML_REQUEST}
                                                style={{marginRight: '0.5em'}} text={"XML-Anfrage"}/>}
                            {d.documentPresentXkfzResponse &&
                                <DownloadButton dto={d} type={GetRequestAuditDocumentParamsEnum.XKFZ_CONNECT_XML_RESPONSE}
                                                style={{marginRight: '0.5em'}} text={"XML-Antwort"}/>}
                            <AlertButton onClick={() => {
                                setActiveLogLine(d)
                                logLineStore.open()
                            }} />
                        </td>
                    </tr>
                })}
                </tbody>
            </ScrollTable>
        </div>
        <Dialog
            title={"Log"}
            store={logLineStore}
            disabledBodyPadding={true}
        >{activeLogLine && <>
            <article className="message is-warning">
                <div className="message-body" style={{
                    borderRadius: '0'
                }}>
                    <div style={{
                        overflow: 'auto',
                        padding: '0.1em',
                        fontSize: '0.8em',
                        fontFamily: 'monospace',
                        backgroundColor: 'var(--ct-primary-font-color)',
                        color: 'var(--ct-background-variant-color)',
                    }}>
                        [{activeLogLine.creationDate}]
                        Created request {activeLogLine.requestId} for process {activeLogLine.registrationId} by user {activeLogLine.principalId} {activeLogLine.user && <>({activeLogLine.user.id})</>} from {activeLogLine.address}
                    </div>
                </div>
            </article>
        </>}
        </Dialog>
    </>
})

export {
    RequestLog
}
