import {observer} from "mobx-react";
import React from "react";
import {
    AddDocumentParamsEnum,
    ListAvailableDocumentsEnum,
    RegistrationDtoOrderStatusEnum,
    RegistrationDtoServiceTypeEnum
} from "../../../../../api";
import {DocumentElement} from "./DocumentElement";
import {registrationDocumentStore, registrationStore} from "../../../../../store";


function isSendable(docType: ListAvailableDocumentsEnum) {
    return docType === ListAvailableDocumentsEnum.PRELIMINARY_REGISTRATION_PROOF ||
        docType === ListAvailableDocumentsEnum.REGISTRATION_RECEIPT ||
        docType === ListAvailableDocumentsEnum.FEE_RECEIPT ||
        docType === ListAvailableDocumentsEnum.DEREGISTRATION_RECEIPT
}

const DocumentPanel = observer(function DocumentUploadPanel({
                                                                finishedOnly
                                                            }: {
    finishedOnly?: boolean
}) {
    const disabled = registrationStore.isReadonly
    const displayOnly = registrationStore.status === RegistrationDtoOrderStatusEnum.SUCCESS || registrationStore.status === RegistrationDtoOrderStatusEnum.FINISHED
    const documents = registrationDocumentStore.documents
    return <>
        {!displayOnly && finishedOnly ? <></> : <>
            <nav className="panel">
                <p className="panel-heading">Dokumente</p>
                <div className="panel-block">
                    <div>
                        {documents.map(d => {
                            return <DocumentElement key={d} type={d} displayOnly={true} sendable={isSendable(d)} />
                        })}
                        {!finishedOnly && <>
                            {!registrationStore.isCorporate &&
                                <DocumentElement
                                    type={AddDocumentParamsEnum.DATA_CONSENT}
                                    generateDisabled={!registrationStore.readyToGenerateDPA}
                                    disabled={disabled}
                                    displayOnly={displayOnly}/>}
                            {!registrationStore.selfRegistration &&
                                <DocumentElement
                                    type={AddDocumentParamsEnum.POA}
                                    disabled={disabled}
                                    generateDisabled={!registrationStore.readyToGeneratePOA}
                                    displayOnly={displayOnly}/>
                            }
                            {registrationStore.serviceType !== RegistrationDtoServiceTypeEnum.HA &&
                                <DocumentElement
                                    type={AddDocumentParamsEnum.SEPA}
                                    disabled={disabled}
                                    generateDisabled={!registrationStore.readyToGenerateSEPA}
                                    displayOnly={displayOnly}/>
                            }
                        </>}
                    </div>
                </div>
            </nav>
        </>}
    </>
})

export {
    DocumentPanel
}
