import {observer} from "mobx-react";
import {AppHeader, AppPage} from "../../app";
import {DialogStore, growlStore, organizationListStore, organizationStore} from "../../../store";
import {Dialog, Scroller} from "../../ui";
import {OrganizationList} from "./OrganizationList";
import {useState} from "react";
import {OrganizationForm} from "./OrganizationForm";


async function createOrganization() {
    const name = organizationStore.name
    await organizationStore.save()
    await organizationListStore.load()
    growlStore.addMessage({
        message: `Standort ${name} wurde angelegt`,
        type: 'success',
        autoHide: true
    })
}

const OrganizationOverviewPage = observer(function OrganizationOverviewPage() {
    const [dialogStore] = useState(new DialogStore())

    function openCreateDialog() {
        dialogStore.modalStore.open()
    }
    return <AppPage>
        <AppHeader title={"Standorte"}>
            <button className={"button is-primary"} onClick={() => {openCreateDialog()}}>Standort anlegen</button>
        </AppHeader>
        <Scroller>
            <OrganizationList elements={organizationListStore.tree} />
        </Scroller>
        <Dialog store={dialogStore} title={<>
            <span className="material-symbols-outlined" style={{color: "var(--bulma-primary)", marginRight: "0.25em"}}>apartment</span> Standort anlegen
        </>} primaryAction={async () => {
            await createOrganization()
            return true
        }} primaryActionTitle={"Speichern"}>
            <OrganizationForm />
        </Dialog>
    </AppPage>
})

export {
    OrganizationOverviewPage
}
