import {observer} from "mobx-react";
import {hasAuthParams, useAuth} from "react-oidc-context";
import React from "react";
import {RouterProvider} from "react-router-dom";
import {authStore, tenantSelectStore} from "../../store";
import {provider} from "./Router";
import {tenantselection} from "../page";
import {baseUrl} from "../../config/page";

function NavigateToLogin() {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = React.useState(false);
    React.useEffect(() => {
        if (!auth.isLoading && !auth.activeNavigator && !hasTriedSignin) {
            if (!hasAuthParams()) {
                auth.signinRedirect();
                setHasTriedSignin(true);
            } else {
                window.location.href = baseUrl!!
            }
        }

    }, [auth, hasTriedSignin]);
    return <></>
}

const AuthenticatedApp = observer(function AuthenticatedApp() {
    const auth = useAuth();

    React.useEffect(() => {
        authStore.auth = auth
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addAccessTokenExpiring(async () => {
            await auth.signinSilent();
        })
    }, [auth, auth.events, auth.signinSilent]);

    switch (auth.activeNavigator) {
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.error) {
        return <NavigateToLogin />;
    }

    if (auth.isAuthenticated || auth.isLoading) {
        if (authStore.accessToken) {
            if (tenantSelectStore.superadmin && !tenantSelectStore.tenant) {
                return <tenantselection.TenantSelectionPage />
            }
            return (
                <RouterProvider router={provider.router}/>
            );
        } else if(auth.isAuthenticated) {
            return <div>waiting...</div>
        } else {
            return <div>loading...</div>
        }
    }
    return <NavigateToLogin />;
})

export {
    AuthenticatedApp
}
